/*
Template Name: Metronic - Responsive Admin Dashboard Template build with Twitter Bootstrap 3.0
Version: 1.5.2
Author: KeenThemes
Website: http://www.keenthemes.com/
Purchase: http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keentheme
*/


/***
Reset and overrides
***/
/* general body settings */
body {
    color: #000;
    font-family: 'Open Sans', sans-serif;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 13px;
    direction: ltr;
    overflow-y: scroll;
}

/*
Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't
properly apply the media queries in Bootstrap's CSS. To address this,
you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
    width: device-width;
}

@-moz-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

/* Internet Explorer 10 doesn't differentiate device width from viewport width,
and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied */
@-ms-viewport {
    width: auto !important;
}

/* Fix Google Maps canvas
 *
 * Wrap your Google Maps embed in a `.google-map-canvas` to reset Bootstrap's
 * global `box-sizing` changes. You may optionally need to reset the `max-width`
 * on images in case you've applied that anywhere else. (That shouldn't be as
 * necessary with Bootstrap 3 though as that behavior is relegated to the
 * `.img-responsive` class.)
 */

.google-map-canvas,
.google-map-canvas * {
    box-sizing: content-box;
}

/* Optional responsive image override */
/*img {*/
/*    max-width : none;*/
/*}*/

/***
General typography
***/
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300 !important;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
    padding-top: 10px;
    padding-bottom: 10px;
}

a {
    text-shadow: none !important;
    color: #0d638f;
}

/***
Fix link outlines after click
***/
a, a:focus, a:hover, a:active {
    outline: 0;
}

/***
General backgrounds. Can be applied to any block or panel
***/

.bg-blue {
    background-image: none !important;
    background-color: #4b8df8 !important;
    border-color: #4b8df8 !important;
    color: #fff !important;
}

.bg-red {
    background-image: none !important;
    background-color: #e02222 !important;
    border-color: #e02222 !important;
    color: #fff !important;
}

.bg-yellow {
    background-image: none !important;
    background-color: #ffb848 !important;
    border-color: #ffb848 !important;
    color: #fff !important;
}

.bg-green {
    background-image: none !important;
    background-color: #35aa47 !important;
    border-color: #35aa47 !important;
    color: #fff !important;
}

.bg-purple {
    background-image: none !important;
    background-color: #852b99 !important;
    border-color: #852b99 !important;
    color: #fff !important;
}

.bg-dark {
    background-image: none !important;
    background-color: #555555 !important;
    border-color: #555555 !important;
    color: #fff !important;
}

.be-grey {
    background-image: none !important;
    background-color: #fafafa !important;
    border-color: #fafafa !important;
}

/***
Font Awesome Icons
***/
[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    margin-top: 1px;
    font-size: 14px;
    margin-right: .3em;
    line-height: 14px;
}

/***
Make font awesome icons fixed width(latest version issue)
***/
li [class^="icon-"],
li [class*=" icon-"] {
    display: inline-block;
    width: 1.25em;
    text-align: center;
}

li [class^="icon-"].icon-large,
li [class*=" icon-"].icon-large {
    /* increased font size for icon-large */
    width: 1.5625em;
}

/***
Close icon used for modal dialog and other UI element close buttons
***/
.close {
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    width: 9px;
    height: 9px;
    background-repeat: no-repeat !important;
    background-image: url("../../images/remove-icon-small.png") !important;
}

/***
General HR
***/

hr {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #E0DFDF;
    border-bottom: 1px solid #FEFEFE;
}

/***
Tools
***/
.display-none,
.display-hide {
    display: none;
}

.no-space {
    margin: 0 !important;
    padding: 0 !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.bold {
    font-weight: 600 !important;
}

.fix-margin {
    margin-left: 0 !important
}

.border {
    border: 1px solid red;
}

.inline {
    display: inline;
}

/***
ie8 & ie9 modes
***/
.visible-ie8 {
    display: none;
}

.ie8 .visible-ie8 {
    display: inherit !important;
}

.visible-ie9 {
    display: none;
}

.ie9 .visible-ie9 {
    display: inherit !important;
}

.hidden-ie8 {
    display: inherit;
}

.ie8 .hidden-ie8 {
    display: none !important;
}

.hidden-ie9 {
    display: inherit;
}

.ie9 .hidden-ie9 {
    display: none !important;
}

.ui-datepicker {
    z-index: 9999 !important;
}

/********************
 GENERAL LAYOUT
*********************/

/***
Header and header elements.
***/

.header {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    box-shadow: none;
    height: 42px;
    min-height: 42px;
}

.header.navbar-fixed-top {
    z-index: 1995 !important;
}

.header .navbar-brand {
    display: inline-block;
    margin-top: -1px;
    margin-right: 0;
    padding: 10px 0;
    width: 225px;
}

.header .navbar-brand img:first-of-type {
    margin-left: 20px;
}

.header .navbar-brand img {
    display: inline;
}

.logo-opsat {
    position: relative;
    top: 1px;
}

.header .navbar-toggle {
    margin: 8px 6px 4px 6px;
    padding: 2px 0 6px;
    background-image: none;
    filter: none;
    box-shadow: none;
    color: #fff;
    border: 0;
}

.header .navbar-toggle:hover {
    text-decoration: none;
    background: none;
}

.header .navbar-nav {
    margin-right: 20px;
    display: block;
}

.header .navbar-nav > li {
    margin: 0;
    padding: 0;
}

.header .navbar-nav > li.dropdown,
.header .navbar-nav > li.dropdown > a {
    padding-left: 4px;
    padding-right: 4px;
}

.header .navbar-nav > li.dropdown > a:last-child {
    padding-right: 0;
}

.header .navbar-nav > li.dropdown:last-child {
    padding-right: 2px;
}

.header .navbar-nav > li.dropdown .dropdown-toggle {
    margin: 0;
    padding: 15px 10px 7px 10px;
}

.header .navbar-nav > li.dropdown .dropdown-toggle > i {
    font-size: 18px;
}

.header .navbar-nav > li.dropdown .dropdown-menu > li > a > i {
    font-size: 14px;
}

.header .navbar-nav > li.dropdown.user .dropdown-toggle {
    padding: 7px 4px 6px 9px;
}

.header .navbar-nav > li.dropdown.user .dropdown-toggle:hover {
    text-decoration: none;
}

.header .navbar-nav > li.dropdown.user .dropdown-toggle .username {
    color: #ddd;
}

.header .navbar-nav li.dropdown.user .dropdown-toggle i {
    display: inline-block;
    margin: 5px 0 0;
    font-size: 16px;
}

.header .navbar-nav > li.dropdown.user .dropdown-menu i {
    width: 15px;
    display: inline-block;
}

.header .navbar-nav > li.dropdown .dropdown-toggle .badge {
    position: absolute;
    top: 8px;
    right: 20px;
}

/* language bar */

.header .navbar-nav > li.dropdown.language {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
}

.header .navbar-nav > li.dropdown.language > a {
    color: #ddd;
    font-size: 13px;
    padding: 10px 1px 12px 5px;
}

.header .navbar-nav > li.dropdown.language > a > img {
    margin-bottom: 2px;
}

.header .navbar-nav > li.dropdown.language > a > i {
    font-size: 16px;
}

.header .navbar-nav > li.dropdown.language > .dropdown-menu > li > a > img {
    margin-bottom: 2px;
}

.header .navbar-nav .dropdown-menu {
    margin-top: 3px;
}

.header .main-organization {
    position: absolute;
    top: 9px;
    left: 192px;
    color: #aaa;
    font-size: 18px;
    text-transform: uppercase;
}

/***
Page container
***/
.page-container {
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 75px);
}

.page-header-fixed .page-container {
    margin-top: 42px;
}

/***
Page sidebar
***/

/* ie8 fixes */
.ie8 .page-sidebar {
    position: absolute;
    width: 225px;
}

.page-sidebar.navbar-collapse {
    padding: 0;
}

.page-sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.page-sidebar-menu > li {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
}

.page-sidebar-menu > li.start > a {
    border-top-color: transparent !important;
}

.page-sidebar-menu > li:last-child > a,
.page-sidebar-menu > li.last > a {
    border-bottom-color: transparent !important;
}

.page-sidebar-menu > li > a {
    display: block;
    position: relative;
    margin: 0;
    border: 0;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
}

.page-sidebar-fixed .page-sidebar-menu > li > a {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.page-sidebar-menu > li > a svg {
    font-size: 14px;
    margin-right: 10px;
    text-shadow: none;
}

.page-sidebar-menu > li.break {
    margin-bottom: 20px;
}

.page-sidebar-menu > li.active > a {
    border: none;
    text-shadow: none;
}

.page-sidebar-menu > li.active > a .selected {
    display: block;
    width: 8px;
    height: 25px;
    background-image: url("../../images/sidebar-menu-arrow.png");
    float: right;
    position: absolute;
    right: 0;
    top: 8px;
}

.page-sidebar ul > li > a > .arrow:before {
    float: right;
    margin-top: 0;
    margin-right: 5px;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome, sans-serif;
    height: auto;
    content: "\f104";
    font-weight: 300;
    text-shadow: none;
}

.page-sidebar-menu > li > a > .arrow.open:before {
    float: right;
    margin-top: 0;
    margin-right: 3px;
    display: inline;
    font-family: FontAwesome, sans-serif;
    height: auto;
    font-size: 16px;
    content: "\f107";
    font-weight: 300;
    text-shadow: none;
}

/* bagin: sidebar menu badges */
.page-sidebar-menu li > a > .badge {
    float: right;
    margin-top: 1px;
    margin-right: 13px;
}

/* end: sidebar menu badges */

.page-sidebar-menu .sub-menu {
    padding: 0;
}

.page-sidebar-menu > li > ul.sub-menu {
    list-style: none;
    clear: both;
    margin: 8px 0 8px 0;
}

.page-sidebar-menu > li > ul.sub-menu > li {
    background: none;
    margin: 0;
    padding: 0;
    margin-top: 1px !important;
}

.page-sidebar-menu > li > ul.sub-menu > li > a {
    display: block;
    margin: 0 0 0 0;
    padding: 5px 0;
    padding-left: 44px !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    background: none;
}

/* 3rd level sub menu */
.page-sidebar-menu > li > ul.sub-menu > li ul.sub-menu {
    list-style: none;
    clear: both;
    margin: 0 0 0 0;
}

.page-sidebar-menu > li > ul.sub-menu li > a > .arrow:before {
    float: right;
    margin-top: 1px;
    margin-right: 20px;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome, sans-serif;
    height: auto;
    content: "\f104";
    font-weight: 300;
    text-shadow: none;
}

.page-sidebar-menu > li > ul.sub-menu li > a > .arrow.open:before {
    float: right;
    margin-top: 1px;
    margin-right: 18px;
    display: inline;
    font-family: FontAwesome, sans-serif;
    height: auto;
    font-size: 16px;
    content: "\f107";
    font-weight: 300;
    text-shadow: none;
}


.page-sidebar-menu > li > ul.sub-menu > li ul.sub-menu li {
    background: none;
    margin: 0;
    padding: 0;
    margin-top: 1px !important;
}

.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li > a {
    display: block;
    margin: 0 0 0 0;
    padding: 5px 0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    background: none;
}

.page-sidebar-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
    padding-left: 60px;
}

.page-sidebar-menu > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
    padding-left: 80px;
}

.page-sidebar-menu > li.active > ul.sub-menu > li.active ul.sub-menu > li.active ul.sub-menu {
    display: block;
}

.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li > a > i {
    font-size: 13px;
}

/* sidebar search */
.page-sidebar .sidebar-search {
    padding: 0;
    margin: 0;
}

.page-sidebar .sidebar-search .form-container {
    margin: 15px 20px 15px 20px;
    height: 35px;
    padding-top: 7px;
}

.page-sidebar .sidebar-search .form-container .submit {
    display: block;
    float: right;
    margin-top: 3px;
    width: 13px;
    height: 15px;
    background-repeat: no-repeat;
    box-shadow: none;
    border: 0;
    padding: 0;
    outline: none !important;
}

.page-sidebar .sidebar-search .form-container input[type="text"] {
    margin: 0;
    width: 165px;
    border: 0;
    padding: 0 !important;
    font-size: 14px !important;
    box-shadow: none !important;
    font-weight: normal;
}

.page-sidebar .sidebar-search .form-container input[type="text"]:focus {
    outline: none !important;
}

/***
Sidebar toggler(show/hide)
***/

.page-sidebar .sidebar-toggler {
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
    margin-top: 15px;
    margin-left: 175px;
    width: 29px;
    height: 29px;
    background-repeat: no-repeat;
}

.sidebar-toggler:hover {
    filter: alpha(opacity=100);
    opacity: 1;
}

.page-sidebar-closed .sidebar-toggler {
    margin-left: 3px;
}

.page-sidebar-closed .page-sidebar .sidebar-search .form-container {
    width: 29px;
    margin-left: 3px;
}

.page-sidebar-closed .page-sidebar .sidebar-search .form-container .input-box {
    border-bottom: 0 !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search .form-container input[type="text"] {
    display: none;
}

.page-sidebar-closed .page-sidebar .sidebar-search .form-container .submit {
    margin-top: 5px !important;
    margin-left: 7px !important;
    margin-right: 7px !important;
    display: block !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
    width: 255px;
    position: relative;
    z-index: 1;
    padding-top: 0;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container input[type="text"] {
    margin-top: 7px;
    margin-left: 8px;
    padding-left: 10px;
    padding-bottom: 2px;
    width: 185px;
    display: inline-block !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .submit {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 11px 8px 9px 6px !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: 12px 8px 9px 8px !important;
    display: inline-block !important;
    float: left !important;
}

.page-sidebar-closed .page-sidebar-menu > li > a .selected {
    right: -3px !important;
}

.page-sidebar-closed .page-sidebar-menu > li > a > .title,
.page-sidebar-closed .page-sidebar-menu > li > a > .arrow {
    display: none !important;
}

.page-sidebar-closed .sidebar-toggler {
    margin-right: 3px;
}

.page-sidebar-closed .page-sidebar .sidebar-search {
    margin-top: 6px;
    margin-bottom: 6px;
}

.page-sidebar-closed .page-sidebar-menu {
    width: 35px !important;
}

.page-sidebar-closed .page-sidebar-menu > li > a {
    padding-left: 7px;
}

.page-sidebar-fixed.page-sidebar-closed .page-sidebar-menu > li > a {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.page-sidebar-closed .page-sidebar-menu > li:hover {
    width: 236px !important;
    position: relative !important;
    z-index: 2000;
    display: block !important;
}

.page-sidebar-closed .page-sidebar-menu > li:hover .selected {
    display: none;
}

.page-sidebar-closed .page-sidebar-menu > li:hover > a > i {
    margin-right: 10px;
}

.page-sidebar-closed .page-sidebar-menu > li:hover .title {
    display: inline !important;
}

.page-sidebar-closed .page-sidebar-menu > li > .sub-menu {
    display: none !important;
}

.page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu {
    width: 200px;
    position: absolute;
    z-index: 2000;
    left: 36px;
    margin-top: 0;
    top: 100%;
    display: block !important;
}

.page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > .sub-menu,
.page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > .sub-menu > li > .sub-menu {
    width: 200px;
}

/* 2rd level sub menu*/
.page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > a {
    padding-left: 15px !important;
}

/* 3rd level sub menu*/
.page-sidebar-closed .page-sidebar-menu > li > ul.sub-menu > li > .sub-menu > li > a {
    padding-left: 30px !important;
}

/* 4rd level sub menu*/
.page-sidebar-closed .page-sidebar-menu > li > ul.sub-menu > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px !important;
}

/* sidebar container */

.page-sidebar-closed .page-sidebar {
    width: 35px;
}

.page-sidebar-closed .page-content {
    margin-left: 35px !important;
}

/***
Page content
***/
.page-content {
    margin-top       : 0;
    padding          : 0;
    background-color : #fff;
    position: relative;
    overflow: hidden;
}

.ie8 .page-content {
    padding: 20px;
    margin-left: 225px;
    margin-top: 0;
    min-height: 760px;
}

.ie8 .page-sidebar-fixed .page-content {
    min-height: 600px;
}

.ie8 .page-content.no-min-height {
    min-height: auto;
}

.page-full-width .page-content {
    margin-left: 0 !important;
}

/***
Page title
***/
.page-title {
    padding: 0;
    font-size: 30px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0 0 15px 0;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.page-title small {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
    color: #888;
}

/***
Page breadcrumb
***/

.breadcrumb li .icon-angle-right {
    width: 1.25em;
}

.ie8 .row .page-breadcrumb.breadcrumb > li {
    margin-right: 1px;
}

#breadcrumb-container {
    height: 62px;
    position: static;
}

.row.filters{
    margin-bottom : 25px;
}

.page-content .page-breadcrumb.breadcrumb {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    padding-right: 30px;
    padding-left: 8px;
    margin-top: 15px;
    margin-bottom: 25px;
    border: 0 !important;
    background-color: #eee;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    padding: 9px 15px;
}

.page-content .page-breadcrumb.breadcrumb.affix li.btn-group {
    right: 0;
}

.page-content .page-breadcrumb.breadcrumb > li > a,
.page-content .page-breadcrumb.breadcrumb > li > svg,
.page-content .page-breadcrumb.breadcrumb > li > span {
    color: #333;
    font-size: 14px;
    text-shadow: none;
}

.page-content .page-breadcrumb.breadcrumb > li > a.red {
    color: #fff;
}


.page-content .page-breadcrumb.breadcrumb > li {
    vertical-align: middle;
}

.page-content .page-breadcrumb.breadcrumb > li > svg {
    color: #666;
    margin-right: 5px;
    font-size: 12px;
    line-height: 14px;
}

.page-content .page-breadcrumb.breadcrumb > li > svg.icon-angle-right {
    margin-right: 0;
}

.page-content .page-breadcrumb.breadcrumb > li + li:before {
    display: none;
}

/* Dashboard breadcrumb Dropdown */
.page-content .page-breadcrumb.breadcrumb .btn-group {
    right: 0;
    position: absolute;
    margin-top: -8px;
}

.page-content .page-breadcrumb.breadcrumb > .btn-group .btn {
    padding-top: 8px;
    padding-bottom: 8px;
}

/* Dashboard date range panel */
.page-content .page-breadcrumb.breadcrumb .dashboard-date-range {
    position: relative;
    top: -8px;
    margin-right: -30px;
    display: none;
    padding: 9px 9px 8px 9px;
    cursor: pointer;
    color: #fff;
    background-color: #e02222;
}

/* hack for chrome and safari */
@media all and (-webkit-min-device-pixel-ratio: 0) {
    .page-content .page-breadcrumb.breadcrumb .dashboard-date-range {
        padding: 9px;
    }
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range > span {
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range > .icon-calendar {
    text-transform: none;
    color: #fff;
    margin-top: 0;
    font-size: 14px;
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range > .icon-angle-down {
    color: #fff;
    font-size: 16px;
}

/***
Footer
***/

.footer {
    padding: 8px 20px 5px 20px;
    font-size: 12px;
    position: fixed;
    z-index: 199;
    background-color: #fafafa;
    bottom: 0;
    right: 0;
    width: 100%;
    border-top: 1px solid #e2e2e2 !important;
}

.footer:after,
.footer:before {
    content: "";
    display: table;
    line-height: 0;
}

.footer:after {
    clear: both;
}

.footer .footer-inner {
    float: right;
    display: inline-block;
    margin-right: 20px;
    font-size: 9px;
    margin-top: 4px;
}

.footer .footer-inner .wd {
    color: #00abff;
}

.footer .footer-tools {
    float: right;
    display: inline-block;
}

.footer .footer-tools .go-top {
    display: block;
    text-decoration: none;
    cursor: pointer;
    margin-top: -2px;
    margin-right: 0;
    margin-bottom: 0;
    font-size: 16px;
    padding: 0 6px 0 6px;
}

.footer .footer-tools .go-top i {
    font-size: 22px;
    margin-bottom: 5px;
}

/********************
 GENERAL UI ELEMENTS
*********************/

/***
Icon stuff
***/
i.icon, a.icon {
    color: #999;
    margin-right: 5px;
    font-weight: normal;
    font-size: 13px;
}

i.icon-black {
    color: #000 !important;
}

a.icon:hover {
    text-decoration: none;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    opacity: .4;
    filter: alpha(opacity=40);
}

a.icon.huge i {
    font-size: 16px !important;
}

i.big {
    font-size: 20px;
}

i.warning {
    color: #d12610;
}

i.critical {
    color: #37b7f3;
}

i.normal {
    color: #52e136;
}

/***
Custom wells
***/
.well {
    background-color: #fafafa;
    border: 1px solid #eee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.well.mini {
    padding: 7px !important;
}

/***
Form stuff
***/

/***
Bordered form layout
***/

/***
Input icons
***/

/* input with right aligned and colored icons */

/* input with left aligned icons */
.input-icon {
    position: relative;
}

.input-icon input {
    padding-left: 33px !important;
}

.input-icon i {
    color: #ccc;
    display: block;
    position: absolute;
    margin: 11px 2px 4px 10px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: center;
}

.input-icon.right input {
    padding-left: 12px !important;
    padding-right: 33px !important;
}

.input-icon.right i {
    right: 8px;
    float: right;
}

.has-success .input-icon > i {
    color: #468847;
}

.has-warning .input-icon > i {
    color: #c09853;
}

.has-error .input-icon > i {
    color: #b94a48;
}

/***
Portlets
***/
.portlet {
    clear: both;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0;
}

.portlet > .portlet-title {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.portlet > .portlet-title:after,
.portlet > .portlet-title:before {
    content: "";
    display: table;
    line-height: 0;
}

.portlet > .portlet-title:after {
    clear: both;
}

.portlet > .portlet-title > .caption {
    /*que si il y a un btn action*/
    max-width: calc(100% - 102px);
    float: left;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    padding: 0;
    margin: 0 0 8px;
}
.portlet.no-action > .portlet-title > .caption {
    max-width: 100%;
}

.portlet > .portlet-title > .caption > i {
    float: left;
    margin-top: 4px;
    display: inline-block !important;
    font-size: 13px;
    margin-right: 5px;
    color: #666;
}

.portlet.blue > .portlet-title > .caption, .portlet-title.blue > .caption,
.portlet.green > .portlet-title > .caption, .portlet-title.green > .caption,
.portlet.yellow > .portlet-title > .caption, .portlet-title.yellow > .caption,
.portlet.red > .portlet-title > .caption, .portlet-title.red > .caption,
.portlet.purple > .portlet-title > .caption, .portlet-title.purple > .caption,
.portlet.grey > .portlet-title > .caption, .portlet-title.dark-grey > .caption {
    color: #fff;
}

.portlet.box.blue > .portlet-title > .caption > i,
.portlet.box.green > .portlet-title > .caption > i,
.portlet.box.grey > .portlet-title > .caption > i,
.portlet.box.yellow > .portlet-title > .caption > i,
.portlet.box.red > .portlet-title > .caption > i,
.portlet.box.purple > .portlet-title > .caption > i,
.portlet.box.light-grey > .portlet-title > .caption > i {
    color: #fff;
}

.sortable .portlet > .portlet-title {
    cursor: move;
}

.portlet > .portlet-title > .tools,
.portlet > .portlet-title > .actions {
    display: inline-block;
    padding: 0;
    margin: 6px 0 0;
    float: right;
}

.portlet > .portlet-title > .tools > a {
    display: inline-block;
    height: 16px;
    margin-left: 5px;
}

.portlet > .portlet-title > .actions > .dropdown-menu i {
    color: #000 !important;
}

.portlet > .portlet-title > .tools > a.remove {
    margin-bottom: 2px;
    background-image: url(../../images/portlet-remove-icon.png);
    background-repeat: no-repeat;
    width: 11px;
}

.portlet > .portlet-title > .tools > a.config {
    margin-bottom: 2px;
    background-image: url(../../images/portlet-config-icon.png);
    background-repeat: no-repeat;
    width: 12px;
}

.portlet > .portlet-title > .tools > a.reload {
    margin-bottom: 2px;
    background-image: url(../../images/portlet-reload-icon.png);
    width: 13px;
}

.portlet > .portlet-title > .tools > a.expand {
    margin-bottom: 2px;
    background-image: url(../../images/portlet-expand-icon.png);
    width: 14px;
}

.portlet > .portlet-title > .tools > a.collapse {
    margin-bottom: 2px;
    background-image: url(../../images/portlet-collapse-icon.png);
    width: 14px;
}

.portlet > .portlet-title > .tools > a:hover {
    text-decoration: none;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    opacity: .6;
    filter: 'alpha(opacity=60)';
}

.portlet > .portlet-title > .actions > .btn-group {
    margin-top: -12px;
}

.portlet > .portlet-title > .actions > .btn {
    padding: 4px 10px;
    margin-top: -14px;
}

.portlet > .portlet-title > .actions > .btn-group > .btn {
    padding: 4px 10px;
    margin-top: -1px;
}

.portlet > .portlet-title > .actions > .btn.btn-sm {
    padding: 3px 8px;
    margin-top: -14px;
}

.portlet > .portlet-title > .actions > .btn-group > .btn-sm {
    padding: 3px 8px;
    margin-top: -1px;
}

.portlet > .portlet-title > .pagination.pagination-sm {
    float: right !important;
    display: inline-block !important;
    margin: -4px 0 0;
}

.portlet > .portlet-body {
    clear: both;
    padding: 0;
}
.portlet > .portlet-body.form{
    padding-top : 15px !important;
}

.portlet > .portlet-body:after {
    content: "";
    display: block;
    clear: both;
}

.portlet > .portlet-body.light-blue, .portlet.light-blue {
    background-color: #bfd5fa !important;
}

.portlet > .portlet-body.blue, .portlet.blue {
    background-color: #4b8df8 !important;
}

.portlet > .portlet-body.red, .portlet.red {
    background-color: #e02222 !important;
}

.portlet > .portlet-body.yellow, .portlet.yellow {
    background-color: #ffb848 !important;
}

.portlet > .portlet-body.green, .portlet.green {
    background-color: #35aa47 !important;
}

.portlet > .portlet-body.purple, .portlet.purple {
    background-color: #852b99 !important;
}

.portlet > .portlet-body.light-grey, .portlet.light-grey {
    background-color: #fafafa !important;
}

.portlet > .portlet-body.grey, .portlet.grey {
    background-color: #555555 !important;
}

.portlet > .portlet-body .portlet-action {
    display: block;
    padding: 10px;
    margin-bottom: 2px;
}

button.portlet-action {
    border: none;
    background: none;
}

/* Information */
.block-information {
    padding: 0 20px;
    margin-bottom: 30px;
}

.block-information .block-information__title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
}

.block-information .block-information__addr {
    font-weight: bold;
}

.block-information span {
    color: #888888;
}

/*  draggable girds */

.ui-sortable-placeholder {
    border: 1px dotted black;
    visibility: visible !important;
    height: 100% !important;
}

.ui-sortable-placeholder * {
    visibility: hidden;
}

.sortable-box-placeholder {
    background-color: #f5f5f5;
    border: 1px dashed #DDDDDD;
    display: block;
    /* float: left;*/
    margin-top: 0 !important;
    margin-bottom: 24px !important;
}

.sortable-box-placeholder * {
    visibility: hidden;
}

/***
Solid colored portlet
***/
.portlet.solid {
    padding: 10px;
}

.portlet.solid > .portlet-title > .tools {
    margin-top: 2px;
    border: 0;
}

.portlet.solid > .portlet-title {
    margin-bottom: 5px;
    border: 0;
}

.portlet.solid.bordered > .portlet-title {
    margin-bottom: 15px;
}

.portlet.solid.red > .portlet-title,
.portlet.solid.red > .portlet-title > .caption > i,
.portlet.solid.red > .portlet-body,
.portlet.solid.green > .portlet-title,
.portlet.solid.green > .portlet-title > .caption > i,
.portlet.solid.green > .portlet-body,
.portlet.solid.yellow > .portlet-title,
.portlet.solid.yellow > .portlet-title > .caption > i,
.portlet.solid.yellow > .portlet-body,
.portlet.solid.grey > .portlet-title,
.portlet.solid.grey > .portlet-title > .caption > i,
.portlet.solid.grey > .portlet-body,
.portlet.solid.purple > .portlet-title,
.portlet.solid.purple > .portlet-title > .caption > i,
.portlet.solid.purple > .portlet-body,
.portlet.solid.blue > .portlet-title,
.portlet.solid.blue > .portlet-title > .caption > i,
.portlet.solid.blue > .portlet-body {
    border: 0;
    color: #fff;
}

.portlet.bordered {
    border-left: 2px solid #ddd;
}

/***
Box portlet
***/

.portlet.box {
    padding: 0 !important
}

.portlet.box > .portlet-title {
    padding: 8px 10px 2px 10px;
    border-bottom: 1px solid #eee;
    color: #fff !important;
}

.portlet.box > .portlet-title > .actions > .btn > i {
    color: #fff !important;
}

.portlet.box > .portlet-title > .tools {
    margin-top: 3px;
}

.portlet.box > .portlet-title > .tools > a.remove,
.portlet.solid > .portlet-title > .tools > a.remove {
    background-image: url(../../images/portlet-remove-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.config,
.portlet.solid > .portlet-title > .tools > a.config {
    background-image: url(../../images/portlet-config-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.reload,
.portlet.solid > .portlet-title > .tools > a.reload {
    background-image: url(../../images/portlet-reload-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.expand,
.portlet.solid > .portlet-title > .tools > a.expand {
    background-image: url(../../images/portlet-expand-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.collapse,
.portlet.solid > .portlet-title > .tools > a.collapse {
    background-image: url(../../images/portlet-collapse-icon-white.png);
}

/* portlet buttons */
.accueil-box svg {
    font-size: 8em;
    line-height: 1em;
    color: #444444;
}

.accueil-box {
    padding-bottom: 20px;
    padding-top: 20px;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
}

.align-items-center{
    align-items: center;
}

.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.align-content-center {
    -ms-flex-line-pack: center;
    align-content: center;
}

.accueil-box .portlet > .portlet-body .portlet-action {
    font-size: 14px;
    line-height: 23px;
    color: #3c3c3c;
    font-weight: 600;
}

.accueil-box .portlet.box > .portlet-body {
    padding: 10px 25px;
}

.accueil-box .portlet.box {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.accueil-box .portlet.box .portlet-title {
    -ms-flex-negative: 1;
    flex-shrink: 1;
}

.accueil-box .portlet.box .portlet-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.portlet.box > .portlet-body {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 10px;
}

.portlet.box > .portlet-title {
    margin-bottom: 0;
}

.portlet.box.blue > .portlet-title {
    background-color: #4b8df8;
}

.portlet.box.blue {
    border: 1px solid #b4cef8;
    border-top: 0;
}

.portlet.box.red > .portlet-title {
    background-color: #e02222;
}

.portlet.box.red {
    border: 1px solid #ef8476;
    border-top: 0;
}

.portlet.box.yellow > .portlet-title {
    background-color: #ffb848;
}

.portlet.box.yellow {
    border: 1px solid #fccb7e;
    border-top: 0;
}

.portlet.box.green > .portlet-title {
    background-color: #35aa47;
}

.portlet.box.green {
    border: 1px solid #77e588;
    border-top: 0;
}

.portlet.box.purple > .portlet-title {
    background-color: #852b99;
}

.portlet.box.purple {
    border: 1px solid #af5cc1;
    border-top: 0;
}

.portlet.box.grey > .portlet-title {
    background-color: #555555;
}

.portlet.box.grey {
    border: 1px solid #9d9c9c;
    border-top: 0;
}

.portlet.box.light-grey > .portlet-title {
    background-color: #aaa;
}

.portlet.box.light-grey {
    border: 1px solid #bbb;
    border-top: 0;
}

.portlet.box.white > .portlet-title {
    background-color: #fdfbfb;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
}

.portlet.box.white {
    border: 1px solid #bbb;
    border-top: 0;
}

.portlet.box.white > .portlet-title {
    color: #000 !important;
}

/***
Charts and statistics
***/
.chart, .pie, .bars {
    overflow: hidden;
    height: 300px;
}

/***
Statistic lists
***/
.item-list.table .percent {
    width: 30px;
    float: right;
    margin-right: 10px;
    margin-top: 3px;
}

.item-list.table .title {
    padding-top: -5px;
}

/***
Chart tooltips
***/
.chart-tooltip {
    clear: both;
    z-index: 100;
    background-color: #736e6e !important;
    padding: 5px !important;
    color: #fff;
}

.chart-tooltip .label {
    clear: both;
    display: block;
    margin-bottom: 2px;
}

/***
Mini chart containers
***/
.bar-chart {
    display: none
}

.line-chart {
    display: none
}

/***
Custom icon buttons
***/
.icon-btn {
    height: 60px;
    min-width: 80px;
    margin: 5px 5px 0 0;
    border: 1px solid #ddd;
    padding: 12px 0 0 0;
    background-color: #fafafa !important;
    background-image: none !important;
    filter: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    display: inline-block !important;
    color: #646464 !important;
    text-shadow: none !important;
    text-align: center;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s ease !important;
    -moz-transition: all 0.3s ease !important;
    -ms-transition: all 0.3s ease !important;
    -o-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
}

.icon-btn i {
    font-size: 18px;
}

.ie8 .icon-btn:hover {
    filter: none !important;
}

.icon-btn:hover {
    text-decoration: none !important;
    border-color: #999 !important;
    color: #444 !important;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 1) !important;
    -webkit-transition: all 0.3s ease !important;
    -moz-transition: all 0.3s ease !important;
    -ms-transition: all 0.3s ease !important;
    -o-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.icon-btn:hover .badge {
    -webkit-transition: all 0.3s ease !important;
    -moz-transition: all 0.3s ease !important;
    -ms-transition: all 0.3s ease !important;
    -o-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.icon-btn div {
    font-family: 'Open Sans', sans-serif;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #000;
    font-size: 12px;
    font-weight: 300;
}

.icon-btn .badge {
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px !important;
    font-weight: 300;
    top: -5px;
    right: -5px;
    padding: 3px 6px 3px 6px;
    color: white !important;
    text-shadow: none;
    border-width: 0;
    border-style: solid;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/* extended dropdowns */
.dropdown-menu.extended {
    min-width: 160px !important;
    max-width: 300px !important;
    width: 233px !important;
    background-color: #ffffff !important;
}

.dropdown-menu.extended:before,
.dropdown-menu.extended:after {
    border-bottom-color: #ddd !important;
}

.dropdown-menu.extended li a {
    display: block;
    padding: 5px 10px !important;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    white-space: normal !important;
}

.dropdown-menu.extended li i {
    margin-right: 3px;
}

.dropdown-menu.extended li a {
    font-size: 13px;
    padding: 10px !important;
    background-color: #ffffff;
}

.dropdown-menu.extended li a:hover {
    background-image: none;
    background-color: #f5f5f5;
    color: #000;
    filter: none;
}

.dropdown-menu.extended li p {
    padding: 10px;
    background-color: #eee;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #000;
}

.dropdown-menu.extended li a {
    padding: 7px 0 5px 0;
    list-style: none;
    border-bottom: 1px solid #f4f4f4 !important;
    font-size: 12px;
    text-shadow: none;
}

.dropdown-menu.extended li:first-child a {
    border-top: none;
    border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li:last-child a {
    border-top: 1px solid white !important;
    border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li.external > a {
    font-size: 13px;
    font-weight: 400;
}

.dropdown-menu.extended li.external > a > i {
    margin-top: 3px;
    float: right;
}

/* header notifications dropdowns */
.dropdown-menu .dropdown-menu-list.scroller {
    padding-right: 0 !important;
    padding-left: 0;
    list-style: none;
}

.dropdown-menu.notification li > a .time {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    font-style: italic;
}

/* header inbox dropdowns */
.dropdown-menu.inbox li > a .photo {
    float: left;
    padding-right: 6px;
}

.dropdown-menu.inbox li > a .photo > img {
    height: 40px;
    width: 40px;
}

.dropdown-menu.inbox li > a .subject {
    display: block;
}

.dropdown-menu.inbox li > a .subject .from {
    font-size: 14px;
    font-weight: 400;
    color: #02689b;
}

.dropdown-menu.inbox li > a .subject .time {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    position: relative;
    float: right;
}

.dropdown-menu.inbox li > a .message {
    display: block !important;
    font-size: 12px;
}

/* header tasks */
.dropdown-menu.tasks .task {
    margin-bottom: 5px;
}

.dropdown-menu.tasks .task .desc {
    font-size: 13px;
    font-weight: 300;
}

.dropdown-menu.tasks .task .percent {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    float: right;
    display: inline-block;
}

.dropdown-menu.tasks .progress {
    display: block;
    height: 11px;
    margin: 0;
}

/***
General list for item with image
***/
.item-list li .img {
    height: 50px;
    width: 50px;
    float: left;
    margin-top: 3px;
    margin-right: 5px;
}

.item-list {
    margin: 0;
    list-style: none;
}

.item-list li {
    padding: 7px 0 5px 0;
    list-style: none;
    border-top: 1px solid white;
    border-bottom: 1px solid #EBEBEB;
    font-size: 12px;
}

.item-list li:first-child {
    border-top: none;
    border-bottom: 1px solid #EBEBEB;
}

.item-list li:last-child {
    border-top: none;
    border-bottom: none;
}

.item-list li .label {
    margin-right: 5px;
}

.item-list.todo li .label {
    position: absolute;
    right: 80px;
}

.item-list.todo li .actions {
    position: absolute;
    right: 45px;
}

/***
Custom tables
***/
.table-toolbar {
    margin-bottom: 15px;
}

.table.table-full-width {
    width: 100% !important;
}

.table .m-btn {
    margin-top: 0;
    margin-left: 0;
    margin-right: 5px;
}

.table thead tr th {
    font-size: 14px;
    font-weight: 600;
}

.table-advance {
    margin-bottom: 10px !important;
}

.table-advance thead {
    color: #999;
}

.table-advance thead tr th {
    background-color: #DDD;
    font-size: 14px;
    font-weight: 400;
    color: #666;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
    position: absolute;
    margin-top: -5px;
    float: left;
    width: 2px;
    height: 30px;
    margin-right: 20px !important;
}

.table-advance tr td {
    border-left-width: 0;
}

.table-advance tr td:first-child {
    border-left-width: 1px !important;
}

.table-advance tr td.highlight:first-child a {
    margin-left: 15px;
}

.table-advance td.highlight div.success {
    border-left: 2px solid #66ee66;
}

.table-advance td.highlight div.info {
    border-left: 2px solid #87ceeb;
}

.table-advance td.highlight div.important {
    border-left: 2px solid #f02c71;
}

.table-advance td.highlight div.warning {
    border-left: 2px solid #fdbb39;
}

.table-advance td.highlight div.danger {
    border-left: 2px solid #e23e29;
}

/***
Star rating
***/
.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 30px;
}

.rating span.star {
    font-family: FontAwesome, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
}

.rating span.star:hover {
    cursor: pointer;
}

.rating span.star:before {
    content: "\f006";
    padding-right: 5px;
    color: #999999;
}

.rating span.star:hover:before,
.rating span.star:hover ~ span.star:before {
    content: "\f005";
    color: #e3cf7a;
}

/***
Item block with details shown on hover
***/
.item {
    overflow: hidden;
    display: block;
    margin-bottom: 20px;
}

.item .details {
    width: 100%;
    display: none;
    background-color: #000;
    color: #fff !important;
    padding: 5px;
    text-align: center;
    position: relative;
    bottom: 30px;
    margin-bottom: -30px;
    overflow: hidden;
    z-index: 6;
}

.item:hover .details {
    display: block;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.item:hover .zoom-icon {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

/***
Zoom icon overlay on images
***/
.zoom {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
}

.zoom .zoom-icon {
    background-image: url("../../images/overlay-icon.png");
    background-color: #222;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    width: inherit;
    height: inherit;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 6;
    top: 0;
}

/***
Chats
***/
.chats {
    padding: 0;
    margin: -15px 0 0;
}

.chats li {
    list-style: none;
    padding: 5px 0;
    margin: 10px auto;
    font-size: 12px;
}

.chats li img.avatar {
    height: 45px;
    width: 45px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.chats li.in img.avatar {
    float: left;
    margin-right: 10px;
}

.chats li .name {
    color: #3590c1;
    font-size: 13px;
    font-weight: 400;
}

.chats li .datetime {
    color: #333;
    font-size: 13px;
    font-weight: 400;
}

.chats li.out img.avatar {
    float: right;
    margin-left: 10px;
}

.chats li .message {
    display: block;
    padding: 5px;
    position: relative;
}

.chats li.in .message {
    text-align: left;
    border-left: 2px solid #35aa47;
    margin-left: 65px;
    background: #fafafa
}

.chats li.in .message .arrow {
    display: block;
    position: absolute;
    top: 5px;
    left: -8px;
    width: 0;
    height: 0;

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #35aa47;
}

.chats li.out .message .arrow {
    display: block;
    position: absolute;
    top: 5px;
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #da4a38;
}

.chats li.out .message {
    border-right: 2px solid #da4a38;
    margin-right: 65px;
    background: #fafafa;
    text-align: right;
}

.chats li.out .name,
.chats li.out .datetime {
    text-align: right;
}

.chats li .message .body {
    display: block;
}

.chat-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #e9eff3;
    overflow: hidden;
    clear: both;
}

.chat-form .input-cont {
    margin-right: 40px;
}

.chat-form .input-cont .form-control {
    width: 100% !important;
    margin-bottom: 0;
}

.chat-form .input-cont input {
    border: 1px solid #ddd;
    width: 100% !important;
    margin-top: 0;
}

.chat-form .input-cont input {
    background-color: #fff !important;
}

.chat-form .input-cont input:focus {
    border: 1px solid #4b8df9 !important;
}

.chat-form .btn-cont {
    margin-top: -42px;
    position: relative;
    float: right;
    width: 44px;
}

.chat-form .btn-cont .arrow {
    position: absolute;
    top: 17px;
    right: 43px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #4d90fe;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.chat-form .btn-cont:hover .arrow {
    border-right-color: #0362fd;
}

.chat-form .btn-cont:hover .btn {
    background-color: #0362fd;
}

.chat-form .btn-cont .btn {
    margin-top: 8px;
}

/***
System feeds
***/
.feeds {
    margin: 0;
    padding: 0;
    list-style: none;
}

.feeds li {
    background-color: #fafafa;
    margin-bottom: 7px;
}

.feeds li:before,
.feeds li:after {
    display: table;
    line-height: 0;
    content: "";
}

.feeds li:after {
    clear: both;
}

.feeds li:last-child {
    margin-bottom: 0;
}

.feeds .col1 {
    float: left;
    width: 100%;
    clear: both;
}

.feeds .col2 {
    float: left;
    width: 75px;
    margin-left: -75px;
}

.feeds .col1 .cont {
    float: left;
    margin-right: 75px;
    overflow: hidden;
}

.feeds .col1 .cont .cont-col1 {
    float: left;
    margin-right: -100%;
}

.feeds .col1 .cont .cont-col1 .label {
    display: inline-block;
    padding: 5px 4px 6px 5px;
    vertical-align: middle;
    text-align: center;
}

.feeds .col1 .cont .cont-col1 .label > i {
    text-align: center;
    font-size: 14px;
}

.feeds .col1 .cont .cont-col2 {
    float: left;
    width: 100%;
}

.feeds .col1 .cont .cont-col2 .desc {
    margin-left: 35px;
    padding-top: 4px;
    padding-bottom: 5px;
    overflow: hidden;
}

.feeds .col2 .date {
    padding: 4px 9px 5px 4px;
    text-align: right;
    font-style: italic;
    color: #c1cbd0;
}

/***
Users
***/
.user-info {
    margin-bottom: 10px !important;
}

.user-info img {
    float: left;
    margin-right: 5px;
}

.user-info .details {
    display: inline-block;
}

.user-info .label {
    font-weight: 300;
    font-size: 11px;
}

/***
Accordions
***/
.accordion-heading {
    background: #eee;
}

.accordion-heading a {
    text-decoration: none;
}

.accordion-heading a:hover {
    text-decoration: none;
}

/***
Vertical inline menu
***/
.ver-inline-menu {
    padding: 0;
    margin: 0;
    list-style: none;
}

.ver-inline-menu li {
    position: relative;
    margin-bottom: 1px;
}

.ver-inline-menu li i {
    width: 37px;
    height: 37px;
    display: inline-block;
    color: #b9cbd5;
    font-size: 15px;
    padding: 12px 10px 10px 8px;
    margin: 0 8px 0 0;
    text-align: center;
    background: #e0eaf0 !important;
}

.ver-inline-menu li a {
    font-size: 13px;
    color: #557386;
    display: block;
    background: #f0f6fa;
    border-left: solid 2px #c4d5df;
}

.ver-inline-menu li:hover a,
.ver-inline-menu li:hover i {
    background: #e0eaf0;
    text-decoration: none;
}

.ver-inline-menu li:hover i {
    color: #fff;
    background: #c4d5df !important;
}

.ver-inline-menu li.active a,
.ver-inline-menu li:hover a {
    font-size: 13px;
}

.ver-inline-menu li.active a {
    border-left: solid 2px #0c91e5;
}

.ver-inline-menu li.active a,
.ver-inline-menu li.active i {
    color: #fff;
    background: #169ef4;
    text-decoration: none;
}

.ver-inline-menu li.active i {
    background: #0c91e5 !important;
}

.ver-inline-menu li.active:after {
    content: '';
    display: inline-block;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 6px solid #169ef4;
    position: absolute;
    top: 12px;
    right: -5px;
}

/***
Custom tabs
***/

.tabbable-custom {
    margin-bottom: 15px;
    padding: 0;
    overflow: hidden;
}

.tabbable-custom > .nav-tabs {
    border: none;
    margin: 0;
}

.tabbable-custom > .tab-content {
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 10px;
}

.tabbable-custom.nav-justified .tab-content {
    margin-top: -1px;
}

.tabs-below.tabbable-custom.nav-justified .tab-content {
    margin-top: 0;
    margin-bottom: -2px;
}

.tabbable-custom.boxless > .tab-content {
    padding: 15px 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.tabbable-custom .nav-tabs > li {
    margin-right: 2px;
    border-top: 2px solid transparent;
}

.tabbable-custom .nav-tabs > li > a {
    margin-right: 0;
}

.tabbable-custom .nav-tabs > li > a:hover {
    background: none;
    border-color: transparent;
}

.tabbable-custom .nav-tabs > li.active {
    border-top: 3px solid #d12610;
    margin-top: 0;
    position: relative;
}

.tabbable-custom .nav-tabs > li.active > a {
    border-top: none;
    font-weight: 400;
}

.tabbable-custom .nav-tabs > li.active > a:hover {
    border-top: none;
    background: #fff;
    border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom .nav-tabs > li {
    margin-right: 2px;
    border-top: 2px solid transparent;
}

/* below tabs */

.tabs-below.tabbable-custom .nav-tabs > li > a {
    border-top: none;
    border-bottom: 2px solid transparent;
    margin-top: -1px;
}

.tabs-below.tabbable-custom .nav-tabs > li.active {
    border-top: none;
    border-bottom: 3px solid #d12610;
    margin-bottom: 0;
    position: relative;
}

.tabs-below.tabbable-custom .nav-tabs > li.active > a {
    border-bottom: none
}

.tabs-below.tabbable-custom .nav-tabs > li.active > a:hover {
    background: #fff;
    border-color: #d4d4d4 #d4d4d4 transparent;
}

/*full width tabs with bigger titles */
.tabbable-custom.tabbable-full-width > .tab-content {
    padding: 15px 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.tabbable-custom.tabbable-full-width .nav-tabs > li > a {
    color: #424242;
    font-size: 15px;
    padding: 9px 15px;
}

/***
Custom portlet tabs
***/
.portlet-tabs > .nav-tabs {
    position: relative;
    top: -41px;
    margin-right: 10px;
    overflow: hidden;
}

.portlet-tabs > .nav-tabs > li {
    float: right;
}

.portlet-tabs > .nav-tabs {
    border-bottom: none;
}

.portlet-tabs > .nav-tabs > li > a {
    color: #fff;
    padding-top: 8px;
    padding-bottom: 10px;
    line-height: 16px;
    margin-top: 6px;
    margin-left: 0;
    margin-right: 0;
    border-left: 0;
    border-right: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.portlet-tabs > .nav-tabs > li:last-child > a {
    border-right: 0;
}

.portlet-tabs > .nav-tabs > li {
    margin-left: 1px;
}

.portlet-tabs > .nav-tabs > li.active {
    color: #333;
    border-top-color: transparent;
}

.portlet-tabs > .nav-tabs > li.active > a {
    margin-bottom: 0;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    border-left: 0;
    border-right: 0;
    background-color: none !important;
    border-top-color: transparent !important;
}

.portlet-tabs > .nav-tabs > li > a:hover {
    color: #333;
    margin-bottom: 0;
    border-bottom-color: transparent;
    margin-left: 0;
    margin-right: 0;
    border-left: 0;
    border-right: 0;
    border-top-color: transparent;
    background-color: #fff;
}

.portlet-tabs > .nav-tabs > .active > a {
    color: #555555;
    cursor: default;
    background-color: #fff;
}

.portlet-tabs > .nav-tabs > .active > a:hover {
    background-color: #fff !important;
}

.portlet-tabs > .tab-content {
    padding: 10px !important;
    margin: 0;
    margin-top: -50px !important;
}

.portlet.tabbable .portlet-body {
    padding: 0;
}

.tab-pane > p:last-child {
    margin-bottom: 0;
}

/***
Dashboard container
***/
#dashboard {
    overflow: hidden;
}

/***
Dashboard stats
***/
.dashboard-stat {
    margin-bottom: 25px;
}

.dashboard-stat:before,
.dashboard-stat:after {
    display: table;
    line-height: 0;
    content: "";
}

.dashboard-stat:after {
    clear: both;
}

.dashboard-stat .visual {
    width: 80px;
    height: 80px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.dashboard-stat .visual i {
    font-size: 65px;
    line-height: 65px;
    color: #fff;
}

@media (min-width: 992px) and (max-width: 1024px) {

    .dashboard-stat .visual i {
        font-size: 28px;
        line-height: 28px;
    }

}

.dashboard-stat .details {
    position: absolute;
    right: 15px;
    padding-right: 10px;
}

.dashboard-stat .details .number {
    padding-top: 15px;
    text-align: right;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: -1px;
    margin-bottom: 5px;
    font-weight: 300;
    color: #fff;
}

.dashboard-stat .details .desc {
    text-align: right;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 300;
    color: #fff;
}

.dashboard-stat .more {
    clear: both;
    display: block;
    padding: 5px 10px 5px 10px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 11px;
    color: #fff;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.dashboard-stat .more:hover {
    text-decoration: none;
    opacity: 1;
    filter: alpha(opacity=100);
}

.dashboard-stat .more > i {
    display: inline-block;
    margin-top: 1px;
    float: right;
}

.dashboard-stat.blue {
    background-color: #27a9e3;
}

.dashboard-stat.blue .more {
    background-color: #208dbe;
}

.dashboard-stat.green {
    background-color: #28b779;
}

.dashboard-stat.green .more {
    background-color: #10a062;
}

.dashboard-stat.red {
    background-color: #e7191b;
}

.dashboard-stat.red .more {
    background-color: #bc0d0e;
}

.dashboard-stat.yellow {
    background-color: #ffb848;
}

.dashboard-stat.yellow .more {
    background-color: #cb871b;
}

.dashboard-stat.purple {
    background-color: #852b99;
}

.dashboard-stat.purple .more {
    background-color: #6e1881;
}

/***
Tiles(new in v1.1.1)
***/
.tiles {
    margin-right: -10px;
}

.tile {
    display: block;
    letter-spacing: 0.02em;
    float: left;
    height: 135px;
    width: 135px !important;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    position: relative;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    font-smooth: always;
    overflow: hidden;
    border: 4px solid transparent;
    margin: 0 10px 10px 0;
}

.tile:after,
.tile:before {
    content: "";
    float: left;
}

.tile.double {
    width: 280px !important;
}

.tile.double-down {
    height: 280px !important;
}

.tile:active, .tile.selected {
    border-color: #ccc !important;
}

.tile:hover {
    border-color: #aaa !important;
}

.tile.selected .corner:after {
    content: "";
    display: inline-block;
    border-left: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-right: 40px solid #ccc;
    position: absolute;
    top: -3px;
    right: -3px;
}

.tile.selected .check:after {
    font-family: FontAwesome, sans-serif;
    font-size: 13px;
    content: "\f00c";
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;
}

.tile * {
    color: #ffffff;
}

.tile .tile-body {
    height: 100%;
    vertical-align: top;
    padding: 10px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    font-smooth: always;
    color: #ffffff;
    margin-bottom: 10px;
}

.tile .tile-body img {
    float: left;
    margin-right: 10px;
}

.tile .tile-body img.pull-right {
    float: right !important;
    margin-left: 10px;
    margin-right: 0;
}

.tile .tile-body .content {
    display: inline-block;
}

.tile .tile-body > i {
    margin-top: 17px;
    display: block;
    font-size: 56px;
    line-height: 56px;
    text-align: center;
}

.tile.double-down i {
    margin-top: 95px;
}

.tile .tile-body h1,
.tile .tile-body h2,
.tile .tile-body h3,
.tile .tile-body h4,
.tile .tile-body h5,
.tile .tile-body h6,
.tile .tile-body p {
    padding: 0;
    margin: 0;
    line-height: 14px;
}

.tile .tile-body h3,
.tile .tile-body h4 {
    margin-bottom: 5px;
}

.tile .tile-body h1:hover,
.tile .tile-body h2:hover,
.tile .tile-body h3:hover,
.tile .tile-body h4:hover,
.tile .tile-body h5:hover,
.tile .tile-body h6:hover,
.tile .tile-body p:hover {
    color: #ffffff;
}

.tile .tile-body p {
    font-weight: 400;
    font-size: 13px;
    font-smooth: always;
    color: #ffffff;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tile .tile-body p:hover {
    color: rgba(0, 0, 0, 0.8);
}

.tile .tile-body p:active {
    color: rgba(0, 0, 0, 0.4);
}

.tile .tile-body p:hover {
    color: #ffffff;
}

.tile.icon > .tile-body {
    padding: 0;
}

.tile .tile-object {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 30px;
    background-color: transparent;
    *zoom: 1;
}

.tile .tile-object:before,
.tile .tile-object:after {
    display: table;
    content: "";
}

.tile .tile-object:after {
    clear: both;
}

.tile .tile-object > .name {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 15px;
    font-weight: 400;
    font-size: 13px;
    font-smooth: always;
    color: #ffffff;
}

.tile .tile-object > .name > i {
    vertical-align: middle;
    display: block;
    font-size: 24px;
    height: 18px;
    width: 24px;
}

.tile .tile-object > .number {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 14px;
    font-smooth: always;
    margin-bottom: 8px;
    margin-right: 10px;
}

/*.tile.image {*/
/*    border-color1 : transparent !important;*/
/*}*/

.tile.image > .tile-body {
    padding: 0 !important;
}

.tile.image > .tile-body > img {
    width: 100%;
    height: auto;
    min-height: 100%;
    max-width: 100%;
}

.tile.image .tile-body h3 {
    display: inline-block;
}

/***
Theme Panel
***/

.theme-panel {
    width: 320px;
    margin-top: -20px;
    margin-right: 1px;
    z-index: 999;
    float: right;
    position: relative;
}

.theme-panel > .toggler {
    top: 4px;
    right: 0;
    padding: 20px;
    cursor: pointer;
    position: absolute;
    background: #c9c9c9 url(../../images/icon-color.png) center no-repeat;
}

.theme-panel > .toggler:hover {
    background-color: #3d3d3d !important;
}

.theme-panel > .toggler-close {
    display: none;
    top: 4px;
    right: 0;
    padding: 20px;
    cursor: pointer;
    position: absolute;
    background: #3d3d3d url(../../images/icon-color-close.png) center no-repeat !important;
}

.theme-panel > .toggler-close:hover {
    background-color: #222 !important;
}

.theme-panel > .theme-options {
    top: 4px;
    right: 40px;
    display: none;
    position: absolute;
    background: #3d3d3d;
}

.theme-panel > .theme-options > .theme-option {
    color: #cfcfcf;
    padding: 15px;
    border-top: 1px solid #585858;
    margin-top: 0;
    margin-bottom: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors {
    border-top: 0;
}

.theme-panel > .theme-options > .theme-option > span {
    text-transform: uppercase;
    display: inline-block;
    width: 85px;
    font-size: 14px;
}

.theme-panel > .theme-options > .theme-option.theme-colors > span {
    display: block;
    width: auto;
}

.theme-panel > .theme-options > .theme-option > select.form-control {
    display: inline;
    width: 100px;
    text-transform: lowercase;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul {
    list-style: none;
    padding: 0;
    display: block;
    margin-bottom: 1px !important;
    margin-top: 10px;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
    width: 30px;
    height: 30px;
    margin: 0 4px;
    cursor: pointer;
    list-style: none;
    float: left;
    border: solid 1px #707070;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
    margin-left: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover,
.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
    border: solid 2px #ebebeb;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-black {
    background: #333438;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
    background: #6d6d6d;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
    background: #124f94;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-brown {
    background: #623f18;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-purple {
    background: #701584;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-white {
    background: #fff;
}

/***
Top bar menu
***/

/* enable arrow for dropdown menu */
.header .nav > li > .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.header .nav > li > .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}

/***
Horezantal Menu(new in v1.2)
***/

.header .hor-menu {
    margin: 0;
    float: left;
}

.header .hor-menu ul.nav li a {
    font-size: 14px;
    padding: 11px 18px;
}

.header .hor-menu ul.nav li {
    position: relative;
}

.header .hor-menu ul.nav li.active > a,
.header .hor-menu ul.nav li.active > a:hover {
    background: #e02222 !important;
}

.header .hor-menu ul.nav li.active .selected {
    left: 50%;
    bottom: 0;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #e02222;
    display: inline-block;
    width: 0;
    height: 0;
    margin: 0 0 -6px -7px;
}

.header .hor-menu ul.nav li a:hover,
.header .hor-menu ul.nav li a:focus {
    background: #2d2d2d;
}

/*drop-down*/
.header .hor-menu .dropdown-menu {
    margin-top: 0;
    border: none;
    box-shadow: none;
    background: #2d2d2d;
}

.header .hor-menu .dropdown-menu li > a {
    color: #999;
    padding: 7px 18px !important;
    margin-bottom: 1px;
}

.header .hor-menu .dropdown-menu .arrow {
    display: none;
}

.header .hor-menu .dropdown-menu li > a:hover,
.header .hor-menu .dropdown-menu li:hover > a,
.header .hor-menu .dropdown-menu li.active > a {
    color: #fff;
    filter: none !important;
    background: #e02222 !important;
}

.header .hor-menu .nav > li > .dropdown-menu:after,
.header .hor-menu .nav > li > .dropdown-menu:before {
    border-bottom: none !important;
}

/*search*/
.header .hor-menu .hor-menu-search-form-toggler {
    display: inline-block;
    padding: 12px 22px 12px 22px !important;
    cursor: pointer;
    background: url(../../images/hor-menu-search.png) no-repeat center;
}

.header .hor-menu .hor-menu-search-form-toggler:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.header .hor-menu .hor-menu-search-form-toggler.off {
    background: #101010 url(../../images/hor-menu-search-close.png) no-repeat center;
}

.header .hor-menu a.hor-menu-search-form-toggler-close {
    display: none;
}

.header .hor-menu .search-form {
    top: 42px;
    right: 0;
    padding: 8px;
    display: none;
    z-index: 999;
    position: absolute;
    background: #101010;
}

.header .hor-menu .search-form .btn {
    color: #999;
    padding: 7px 20px;
    height: 32px;
    width: 10px;
    display: inline-block;
    background: #2d2d2d url(../../images/search-icon.png) no-repeat center;
}

.header .hor-menu .search-form .btn:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.header .hor-menu .search-form form {
    margin-bottom: 0;
}

.header .hor-menu .search-form form input {
    background: none;
    width: 200px;
    color: #999;
    border: none;
}

.header .hor-menu .search-form form input::-webkit-input-placeholder { /* WebKit browsers */
    color: #999;
}

.header .hor-menu .search-form form input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #999;
}

.header .hor-menu .search-form form input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #999;
}

.header .hor-menu .search-form form input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #999;
}

/***
Top News Blocks(new in v1.2.2)
***/
.top-news {
    color: #fff;
    margin: 8px 0;
}

.top-news a,
.top-news em,
.top-news span {
    display: block;
    text-align: left;
}

.top-news a {
    padding: 10px;
    position: relative;
    margin-bottom: 10px;
}

.top-news a .top-news-icon {
    right: 8px;
    bottom: 15px;
    opacity: 0.3;
    font-size: 35px;
    position: absolute;
    filter: alpha(opacity=30); /*For IE8*/
}

.top-news em {
    margin-bottom: 0;
    font-style: normal;
}

.top-news span {
    font-size: 18px;
    margin-bottom: 5px;
}

/***
Block Images(new in v1.2.2)
***/
.blog-images {
    margin-bottom: 0;
}

.blog-images li {
    padding: 0;
    margin: 0;
    display: inline;
}

.blog-images li a:hover {
    text-decoration: none;
}

.blog-images li img {
    width: 50px;
    height: 50px;
    opacity: 0.6;
    margin: 0 2px 8px;
}

.blog-images li img:hover {
    opacity: 1;
    box-shadow: 0 0 0 4px #72c02c;
    transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
}

/*Sidebar Tags*/
ul.sidebar-tags a {
    color: #555;
    font-size: 12px;
    padding: 3px 5px;
    background: #f7f7f7;
    margin: 0 2px 5px 0;
    display: inline-block;
}

ul.sidebar-tags a:hover,
ul.sidebar-tags a:hover i {
    background: #EEE;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

ul.sidebar-tags a i {
    color: #777;
}

ul.sidebar-tags li {
    padding: 0;
}

/***
Social Icons(new in v1.2.2)
***/
.social-icons {
    padding: 0;
    margin: 0;
}

.social-icons:after,
.social-icons:before {
    content: "";
    display: table;
}

.social-icons:after {
    clear: both;
}

.social-icons li {
    float: left;
    display: inline;
    list-style: none;
    margin-right: 5px;
    margin-bottom: 5px;
    text-indent: -9999px;
}

.social-icons li a, a.social-icon {
    width: 28px;
    height: 28px;
    display: block;
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.social-icons li:hover a {
    background-position: 0 -38px;
}

.social-icons-color li a {
    opacity: 0.7;
    background-position: 0 -38px !important;
}

.social-icons-color li a:hover {
    opacity: 1;
}

.social-icons .amazon {
    background: url(../../images/social/amazon.png) no-repeat;
}

.social-icons .behance {
    background: url(../../images/social/behance.png) no-repeat;
}

.social-icons .blogger {
    background: url(../../images/social/blogger.png) no-repeat;
}

.social-icons .deviantart {
    background: url(../../images/social/deviantart.png) no-repeat;
}

.social-icons .dribbble {
    background: url(../../images/social/dribbble.png) no-repeat;
}

.social-icons .dropbox {
    background: url(../../images/social/dropbox.png) no-repeat;
}

.social-icons .evernote {
    background: url(../../images/social/evernote.png) no-repeat;
}

.social-icons .facebook {
    background: url(../../images/social/facebook.png) no-repeat;
}

.social-icons .forrst {
    background: url(../../images/social/forrst.png) no-repeat;
}

.social-icons .github {
    background: url(../../images/social/github.png) no-repeat;
}

.social-icons .googleplus {
    background: url(../../images/social/googleplus.png) no-repeat;
}

.social-icons .jolicloud {
    background: url(../../images/social/jolicloud.png) no-repeat;
}

.social-icons .last-fm {
    background: url(../../images/social/last-fm.png) no-repeat;
}

.social-icons .linkedin {
    background: url(../../images/social/linkedin.png) no-repeat;
}

.social-icons .picasa {
    background: url(../../images/social/picasa.png) no-repeat;
}

.social-icons .pintrest {
    background: url(../../images/social/pintrest.png) no-repeat;
}

.social-icons .rss {
    background: url(../../images/social/rss.png) no-repeat;
}

.social-icons .skype {
    background: url(../../images/social/skype.png) no-repeat;
}

.social-icons .spotify {
    background: url(../../images/social/spotify.png) no-repeat;
}

.social-icons .stumbleupon {
    background: url(../../images/social/stumbleupon.png) no-repeat;
}

.social-icons .tumblr {
    background: url(../../images/social/tumblr.png) no-repeat;
}

.social-icons .twitter {
    background: url(../../images/social/twitter.png) no-repeat;
}

.social-icons .vimeo {
    background: url(../../images/social/vimeo.png) no-repeat;
}

.social-icons .wordpress {
    background: url(../../images/social/wordpress.png) no-repeat;
}

.social-icons .xing {
    background: url(../../images/social/xing.png) no-repeat;
}

.social-icons .yahoo {
    background: url(../../images/social/yahoo.png) no-repeat;
}

.social-icons .youtube {
    background: url(../../images/social/youtube.png) no-repeat;
}

.social-icons .vk {
    background: url(../../images/social/vk.png) no-repeat;
}

.social-icons .instagram {
    background: url(../../images/social/instagram.png) no-repeat;
}

.social-icons .reddit {
    background: url(../../images/social/reddit.png) no-repeat;
}

/***
Inline Social Icons
***/
.social-icon {
    display: inline-block !important;
    width: 28px;
    height: 28px;
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.social-icon.amazon {
    background: url(../../images/social/amazon.png) no-repeat;
}

.social-icon.behance {
    background: url(../../images/social/behance.png) no-repeat;
}

.social-icon.blogger {
    background: url(../../images/social/blogger.png) no-repeat;
}

.social-icon.deviantart {
    background: url(../../images/social/deviantart.png) no-repeat;
}

.social-icon.dribbble {
    background: url(../../images/social/dribbble.png) no-repeat;
}

.social-icon.dropbox {
    background: url(../../images/social/dropbox.png) no-repeat;
}

.social-icon.evernote {
    background: url(../../images/social/evernote.png) no-repeat;
}

.social-icon.facebook {
    background: url(../../images/social/facebook.png) no-repeat;
}

.social-icon.forrst {
    background: url(../../images/social/forrst.png) no-repeat;
}

.social-icon.github {
    background: url(../../images/social/github.png) no-repeat;
}

.social-icon.googleplus {
    background: url(../../images/social/googleplus.png) no-repeat;
}

.social-icon.jolicloud {
    background: url(../../images/social/jolicloud.png) no-repeat;
}

.social-icon.last-fm {
    background: url(../../images/social/last-fm.png) no-repeat;
}

.social-icon.linkedin {
    background: url(../../images/social/linkedin.png) no-repeat;
}

.social-icon.picasa {
    background: url(../../images/social/picasa.png) no-repeat;
}

.social-icon.pintrest {
    background: url(../../images/social/pintrest.png) no-repeat;
}

.social-icon.rss {
    background: url(../../images/social/rss.png) no-repeat;
}

.social-icon.skype {
    background: url(../../images/social/skype.png) no-repeat;
}

.social-icon.spotify {
    background: url(../../images/social/spotify.png) no-repeat;
}

.social-icon.stumbleupon {
    background: url(../../images/social/stumbleupon.png) no-repeat;
}

.social-icon.tumblr {
    background: url(../../images/social/tumblr.png) no-repeat;
}

.social-icon.twitter {
    background: url(../../images/social/twitter.png) no-repeat;
}

.social-icon.vimeo {
    background: url(../../images/social/vimeo.png) no-repeat;
}

.social-icon.wordpress {
    background: url(../../images/social/wordpress.png) no-repeat;
}

.social-icon.xing {
    background: url(../../images/social/xing.png) no-repeat;
}

.social-icon.yahoo {
    background: url(../../images/social/yahoo.png) no-repeat;
}

.social-icon.youtube {
    background: url(../../images/social/youtube.png) no-repeat;
}

.social-icon.vk {
    background: url(../../images/social/vk.png) no-repeat;
}

.social-icon.instagram {
    background: url(../../images/social/instagram.png) no-repeat;
}

.social-icon.reddit {
    background: url(../../images/social/reddit.png) no-repeat;
}

.social-icon:hover {
    background-position: 0 -38px;
}

.social-icon-color {
    opacity: 0.7;
    background-position: 0 -38px !important;
}

.social-icon-color:hover {
    opacity: 1;
}

/***
Notes
***/

/* Common styles for all types */
.note {
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #eee;
}

.note h1,
.note h2,
.note h3,
.note h4 {
    margin-top: 0;
}

.note p:last-child {
    margin-bottom: 0;
}

.note code,
.note .highlight {
    background-color: #fff;
}

/* Variations */
.note-danger {
    background-color: #FAEAE6;
    border-color: #ed4e2a;
}

.note-warning {
    background-color: #FCF3E1;
    border-color: #fcb322;
}

.note-info {
    background-color: #E8F6FC;
    border-color: #57b5e3;
}

.note-success {
    background-color: #EBFCEE;
    border-color: #3cc051;
}

/***
Demo Utils
***/
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 10px;
    overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
    margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
    margin-bottom: 5px !important;
}

.fontawesome-demo i {
    font-size: 18px;
}

.fontawesome-demo li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.glyphicons-demo ul {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;
}

.bs-glyphicons {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;
}

.glyphicons-demo ul li {
    float: left;
    width: 25%;
    height: 115px;
    padding: 10px;
    margin: 0 -1px -1px 0;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
    display: block;
    margin: 5px auto 10px;
    font-size: 24px;
}

.glyphicons-demo ul li:hover {
    background-color: rgba(86, 61, 124, .1);
}

@media (min-width: 768px) {
    .glyphicons-demo ul li {
        width: 12.5%;
    }
}

/***
Forms
****/

input.placeholder,
textarea.placeholder {
    color: #aaa !important;
}

.help-block {
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-inline input {
    margin-bottom: 0 !important;
}

.btn-grp-fix > a > input {
    margin-left: 5px;
}

.control-label {
    margin-top: 2px;
}

.form-control-static {
    font-size: 14px;
    padding-top: 7px;
}

.control-label .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px;
}

.switch-wrapper {
    display: inline-block;
}

/*.form {*/
/*    padding : 0 !important;*/
/*}*/

.form-body {
    padding: 10px;
}

.form-actions {
    padding: 20px 10px;
    margin-top: 20px;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    *zoom: 1;
}

.form-actions.nobg {
    background-color: transparent;
}

.form-actions.top {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 0;
    border-bottom: 1px solid #e5e5e5;
}

.form-actions.fluid {
    padding: 20px 0;
}

.form-actions.fluid > [class^="col-"] {
    padding-left: 13px;
}

.form-actions:before,
.form-actions:after {
    display: table;
    line-height: 0;
    content: "";
}

.form-actions:after {
    clear: both;
}

.form-section {
    margin: 30px 0 25px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

.form .form-section:first-child {
    margin-top: 5px;
}

.help-inline {
    font-size: 13px;
    color: #737373;
    display: inline-block;
    padding: 5px;
}

/* left, right aligned form actions */
.form-actions.right {
    padding-left: 0;
    padding-right: 10px;
    text-align: right;
}

.form-actions.left {
    padding-left: 10px;
    padding-right: 0;
    text-align: left;
}

/* Checkboxes */
.form-group .checkbox {
    padding-left: 0;
}

.checkbox-list > label {
    display: block;
}

.checkbox-list > .el-form-item__label {
    text-align: left;
    float: none;
}

.checkbox-list > label.checkbox-inline {
    display: inline-block;
}

.checkbox-list > label.checkbox-inline:first-child {
    padding-left: 0;
}

.checkbox-list .el-checkbox:last-of-type {
    margin-right: 30px;
}

.el-tooltip__popper {
    max-width: 200px;
}

/* Radios */

.radio-list > label {
    display: block;
}

.radio-list > label.radio-inline {
    display: inline-block;
}

.radio-list > label.radio-inline:first-child {
    padding-left: 0;
}

.form-horizontal .radio-list .radio {
    padding-top: 1px;
}

/* Rows seperated form layout */
.form-row-seperated .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef;
    padding: 10px 10px 10px 0;
}

.form-row-seperated .form-group.last {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.form-row-seperated .form-actions {
    margin-top: 0;
}

.form-row-seperated .form-body {
    padding: 0;
    margin-top: 0;
}

.form-row-seperated .help-block {
    margin-bottom: 0;
}

/* form bordered */
.form-bordered .form-body {
    margin: 0;
    padding: 0;
}

.form-bordered .form-actions {
    margin-top: 0;
}

.form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef;
}

.form-bordered .form-group.last {
    border-bottom: 0;
}

.form-bordered .help-block {
    margin-bottom: 0;
}

.form-bordered .control-label {
    padding-top: 16px;
}

.form-bordered .form-group > div {
    padding: 10px;
    border-left: 1px solid #efefef;
}

.form-bordered .form-actions.fluid > .row > div {
    padding-left: 10px;
}

.form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc;
}

.form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc;
}

.form-horizontal.form-bordered.form-row-stripped .form-control {
    background: #fff !important;
}

.form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) > div {
    background-color: #ffffff;
}

/***
Bordered form layout
***/

.form-bordered .form-control {
    margin: 0;
}

/***
Disabled Menu Link
***/

.disabled-link > a > span.text,
.disabled-link > a > span.title {
    font-style: italic !important;
    color: #888 !important;
}

.disabled-link > a:hover {
    cursor: not-allowed !important;
}

/***
Responsive & Scrollable Tables
***/

.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #dddddd;
    margin: 10px 0 !important;
}

.table-scrollable > .table {
    width: 100% !important;
    margin: 0 !important;
    background-color: #fff;
}

.table-scrollable > .table > thead > tr > th,
.table-scrollable > .table > tbody > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > thead > tr > td,
.table-scrollable > .table > tbody > tr > td,
.table-scrollable > .table > tfoot > tr > td {
    white-space: nowrap;
}

.table-scrollable > .table-bordered {
    border: 0;
}

.table-scrollable > .table-bordered > thead > tr > th:first-child,
.table-scrollable > .table-bordered > tbody > tr > th:first-child,
.table-scrollable > .table-bordered > tfoot > tr > th:first-child,
.table-scrollable > .table-bordered > thead > tr > td:first-child,
.table-scrollable > .table-bordered > tbody > tr > td:first-child,
.table-scrollable > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
}

.table-scrollable > .table-bordered > thead > tr > th:last-child,
.table-scrollable > .table-bordered > tbody > tr > th:last-child,
.table-scrollable > .table-bordered > tfoot > tr > th:last-child,
.table-scrollable > .table-bordered > thead > tr > td:last-child,
.table-scrollable > .table-bordered > tbody > tr > td:last-child,
.table-scrollable > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
}

.table-scrollable > .table-bordered > thead > tr:last-child > th,
.table-scrollable > .table-bordered > tbody > tr:last-child > th,
.table-scrollable > .table-bordered > tfoot > tr:last-child > th,
.table-scrollable > .table-bordered > thead > tr:last-child > td,
.table-scrollable > .table-bordered > tbody > tr:last-child > td,
.table-scrollable > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
}

/***
Responsive Flip Scroll Tables
***/

.flip-scroll table {
    width: 100%;
}

@media only screen and (max-width: 800px) {

    .flip-scroll .flip-content:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .flip-scroll * html .flip-content {
        zoom: 1;
    }

    .flip-scroll *:first-child + html .flip-content {
        zoom: 1;
    }

    .flip-scroll table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .flip-scroll th,
    .flip-scroll td {
        margin: 0;
        vertical-align: top;
    }

    .flip-scroll th {
        text-align: left;
        border: 0 !important;
        border-bottom: 1px solid #ddd !important;
        border-right: 1px solid #ddd !important;
        font-size: 13px !important;
        padding: 5px;
        width: auto !important;
    }

    .flip-scroll table {
        display: block;
        position: relative;
        width: 100%;
    }

    .flip-scroll thead {
        display: block;
        float: left;
    }

    .flip-scroll tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }

    .flip-scroll thead tr {
        display: block;
        border: inherit !important;
    }

    .flip-scroll th {
        display: block;
        text-align: right;
    }

    .flip-scroll tbody tr {
        display: inline-block;
        vertical-align: top;
        margin-left: -5px;
    }

    .flip-scroll td {
        display: block;
        min-height: 1.25em;
        text-align: left;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important
    }

    .flip-scroll td:first-child {
    }

    /* sort out borders */
    .flip-scroll th {
        border-bottom: 0;
        border-left: 0;
    }

    .flip-scroll td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    .flip-scroll tbody tr {
        border-left: 1px solid #ddd;
    }

    .flip-scroll th:last-child,
    .flip-scroll td:last-child {
        border-bottom: 1px solid #ddd;
    }
}

.ui-widget-header .ui-icon {
    background-image: url("../../images/ui-icons_ffffff_256x240.png") !important;
}

.ui-icon, .ui-widget-content .ui-icon {
    background-image: url("../../images/ui-icons_222222_256x240.png") !important;
}

/*ajout pour l'autocomplÃ©tion des formulaires produits*/
.auto-completion {
    background-color: white;
    padding: 10px;
}

.auto-completion:after {
    content: '.';
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: none;
    clear: both;
    color: transparent;
    margin-bottom: 4px;
}

.auto-completion:hover {
    background: #eee;
    cursor: pointer;
}

#product-sheets-filters-results {
    margin: 65px 15px 0 15px;
    position: absolute;
    z-index: 100;
    background: #fff;
    width: 45%;

}

#liste-suggestions {
    display: none;
    border-style: solid;
    border-width: 1px;
}

.auto-completion > span {
    display: inline-block;
}

.sugg {
    display: inline;
}

.description_render {
    display: block;
}

.rotate > span {
    /* -ms-transform: rotate(-90deg);  */
    /* -webkit-transform: rotate(-90deg); */
    /* transform: rotate(-90deg); */
    /* display: inline-block; */
}

.page-content .page-breadcrumb.breadcrumb.affix > .btn.help-button {
    right: 99px;
}

.help-button {
    margin-top: -8px;
    padding: 8px 14px;
    position: absolute;
    right: 114px;
    color: #fff;
    opacity: 0.7;
}

.page-content .page-breadcrumb.breadcrumb > li > i.icon-question {
    color: #fff;
}

.display-print {
    display: none;
}

.select2-modif {
    width: auto !important;
}

.select2-search {
    padding-top: 5px;
    border-top: 1px solid #999999;
}

#autocomplete {
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 34px;
    padding-left: 5px;
}

.hidden-product, .hidden-old {
    display: none !important;
}

thead th {
    font-weight: normal !important;
}

#reconnect-modal {
    overflow: hidden;
    height: 800px;
    border: none;
    box-shadow: none;
    background: transparent;
}

#reconnect-modal .modal-header {
    border: none;
}

#reconnect-modal .modal-body {
    height: 100%;
}

#reconnect-modal iframe {
    width: 100%;
    height: 100%;
    zoom: 0.60;
}

#medical-visit-modal .el-dialog {
    left: 45%
}

#medical-visit-modal .modal-container {
    width: 750px;
}

.el-table .el-loading-mask {
    z-index: 199;
}
