@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../config/fonts";
@import "../config/variables";
@import "../theme/style-metronic.css";
@import "../theme/tree-metronic.css";
@import "../theme/style.css";
@import "../theme/style-responsive.css";
@import "../theme/themeLight.css";
@import "~element-ui/lib/theme-chalk/index.css";
@import "main.css";
@import "../theme/override_element";
@import "../components/modal";
@import "~nprogress/nprogress.css";

#nprogress .bar {
  top: 42px !important;
}

.app-loader {
  .el-loading-spinner {
    i {
      font-size: 42px;
      color: #fff;
    }

    p {
      font-size: 18px;
      color: #fff;
      margin-top: 10px;
    }
  }
}

.pre-formatted {
  white-space: pre-wrap;
}

.slide-enter-active {
  transition-duration: 0.2s;
}

.slide-leave-active {
  transition-duration: 0.2s;
}

.slide-enter-to, .slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slideCotation-enter-active {
  transition: all 0.3s ease-in-out;
}

.slideCotation-leave-active {
  transition: all 0.3s ease-in-out;
}

.slideCotation-enter-to, .slideCotation-leave {
  max-height: 200px;
  overflow: hidden;
}

.slideCotation-enter, .slideCotation-leave-to {
  overflow: hidden;
  max-height: 0;
}

.pie-chart > div.tooltip {
  background: rgba(0, 0, 0, .7);
  border: 1px;
  border-radius: 8px !important;
  opacity: 1;
  color: white;
  min-width: 100px;
  max-width: 150px;
  text-align: center;
  position: absolute;
  pointer-events: none;
}

#pie-risks-quotations {
  background-image: url("../../images/icon-search.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
}

#pie-action-plans {
  background-image: url("../../images/icon-road.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
}

#pie-penibility {
  background-image: url("../../images/icon-user.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
}

#pie-sensibility {
  background-image: url("../../images/icon-cutane-respi.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}

#pie-cmr {
  background-image: url("../../images/icon-cmr.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
}

div.divider {
  display: block;
  width: 75%;
  border-bottom: 1px solid #ccc;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-select {
  display: block;

  .el-input {
    overflow: hidden;

    .el-select__caret {
      padding: 0;
    }
  }
}

.el-input.text-uppercase input {
  text-transform: uppercase;
}

.el-button.el-button--default:focus {
  color: #606266;
  border-color: #dcdfe6;
  background-color: #ffffff;
}
.el-button.el-button--default:hover{
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.el-button.el-button--dark {
  background-color: #555555;
  border-color: #555555;
  color: #FFFFFF;
  padding: 11px 15px;
  margin-top: 10px;
}

.el-button.el-button--red {
  background-color: #d84339;
  border-color: #d84339;
  color: #FFFFFF;
}

.el-button.el-button--red:hover,
.el-button.el-button--red:focus {
  color: #FFFFFF;
  background-color: #bb2413;
}

.page-content .page-breadcrumb.breadcrumb > .btn-group .btn {
  padding: 9px 14px;

  &.pdf-button {
    float: left;
    margin-right: 3px;
    background: #555;
    opacity: 0.7;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 23px;

    svg {
      color: #fff;
    }

    &.help {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.page-content {
  padding-bottom: 34px;
}

.el-pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-user {
  padding: 0;

  li {
    padding: 0;

    a {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 6px 13px 6px 13px;
      line-height: 18px;
      color: #333;

      svg {
        margin-right: 8px;
        margin-left: 8px;
      }

      &:hover {
        text-decoration: none;
        background: #eee;
      }
    }
  }
}

.selectable {
  cursor: pointer;
}

.toolbar-error {
  background-color: red;
  color: white;
  position: absolute;
  bottom: 0;
  padding: 10px;
  margin: 5px;
  z-index: 2000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
