.mini{
    .el-dialog{
        max-width: 450px;
        .el-form-item__label{
            float: none;
            font-weight: bold;
        }
        .el-input{
            width: inherit;
            input{
                width: inherit;
            }
        }
        .el-dialog__body{
            word-break: break-word;
        }
    }
}