.filter-header{
  table{
    .cell{
      white-space: inherit;
    }
  }
}

.el-table {
  &__header-wrapper, &__fixed-header-wrapper {
    thead, thead.is-group {

      th {
        background: #ddd !important;
        color: #666;
        border: 1px solid #bbb;
        text-align: center;

        &.is-leaf {
          border: 1px solid #bbb;
        }

        .el-input {
          margin-top: 10px;
        }
      }
    }
  }

  td {
    padding: 8px 0;
  }

  .cell {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    color: #000;
    -ms-word-break: break-word;
    word-break: break-word;
    //white-space: pre; cette ligne pose problème pour la partie déclaration (SIR/ Poste/ header)

    ul {
      padding-left: 15px;

      li {
        padding-bottom: 10px;
        line-height: 1.6;
      }
    }
  }

  &.table--fds {
    .el-table__row {
      .cell {
        font-size: 10px;

        ul {
          list-style: none;
          padding-left: 0;
        }
      }

      .product-sheet-cell {
        .el-tooltip{
          font-size: 1.25em;
        }
        &__label {
        }

        &__date {
        }

        &__ref {
        }

        &__status {
          .status {
            font-size: 9px;
            max-width: 100%;
            word-break: break-word;
            white-space: normal;
            padding: 3px 6px;
            text-align: center;
            border-radius: 0.25em;
          }

          .status-Sortie {
            background: #9c9c9c;
          }

          .status-0 {
            background: #fafafa;
          }

          .status-1 {
            background: #37b7f3;
          }

          .status-2 {
            background: #d12610;
            color: #fff;
          }

          .status-3 {
            background: #52e136;
          }

          .status-4 {
            background: #d12610;
            color: #fff;
          }

          .status-5 {
            background: #d18a10;
            color: #fff;
          }
        }
      }
    }
  }
}

.table-pagination-container {
  margin-top: 10px;
  text-align: center;
}

.resource-table-container {
  .role-help-table {
    height: 200px;
    font-weight: bold;
    font-size: 0.9em;
    width: 100%;
    margin: 5px;
    table-layout: fixed;

    td {
      border: 1px solid black;
      padding: 10px 0;
    }

    .rotate-90 {
      height: 110px;
      vertical-align: bottom;
      position: relative;
      text-align: left;

      & > div {
        width: 110px;
        transform: rotate(-90deg);
        margin-left: -38px;
        margin-top: 0;
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 55px;
        height: 16px;
      }

      &.lvl3 {
        & > div {
          width: 100px;
          margin-left: -32px;
        }
      }

    }
  }
}

.action-fa-container {
  cursor: pointer;
  padding: 2px;
  margin: 2px;
  color: inherit;
  display: inline-block;
  border: none;
  background: transparent;

  &:hover {
    color: inherit;
  }
}

.limit-area {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  float: left;
}

.select-limit {
  display: inline-block;
  width: 100px;
  margin: 0 5px;
}