.modal-mask {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    padding-top: 40px;
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    min-width: 560px;
    max-width: 60%;
    margin: 0 auto;
    padding: 20px 30px;
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    display: flex;
    flex-flow: column;
    -webkit-border-top-left-radius: 4px !important;
    -webkit-border-top-right-radius: 4px !important;
    -webkit-border-bottom-right-radius: 4px !important;
    -webkit-border-bottom-left-radius: 4px !important;
    -moz-border-radius-topleft: 4px !important;
    -moz-border-radius-topright: 4px !important;
    -moz-border-radius-bottomright: 4px !important;
    -moz-border-radius-bottomleft: 4px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.modal-header-vddl {
    margin-top: 5px;
}
.modal-close{
    display: flex;
    padding: 5px;
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    svg{
        color: #ababab;
    }
}

.modal-header-vddl h3 {
    margin-top: 0;
    color: #852b99;
    font-weight: 600 !important;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0;
}

.modal-body-vddl {
    margin: 13px -15px 13px 0;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100px;
    .well{
        &>div{
            margin-bottom: 10px;
            align-items: center;
        }
        &__label{
            width: 170px;
            font-weight: 400;
            font-size: 14px;
            &.self-start{
                align-self: flex-start;
            }
        }
        &__content{
            img{
                margin-right: 5px;
            }
        }
        ul{
            list-style: none;
            margin-left: 0;
            padding-left: 0;
        }
    }
}

.modal-body-vddl .modal-body__strong {
    font-weight: 600 !important;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-footer-vddl {
    text-align: right;
}

.modal-footer-vddl button {
    background-color: #555555;
    color: #FFFFFF;
    margin-left: 12px;
    border: none;
    padding: 10px 20px;
}

.modal-footer-vddl button[disabled] {
    background-color: #cccccc;
}

.modal_nav-alert {
    width: 640px;
    margin-left: -320px;
    overflow: hidden;
    border: none;
    background: none;
    box-shadow: none;
}

.modal__container {
    padding: 0 20px;
    margin-bottom: 15px;
}

.modal__title {
    color: #852b99;
    font-size: 18px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
    outline: none;
}

.el-tabs__item.is-active {
    color: #852b99 !important;
}

.modal-employee-manage {
    min-height: 558px;
    padding: 15px;
    overflow-y: auto;
    .el-checkbox__label{
        font-size: 12px;
    }
}

.modal-employee-manage .el-tabs__content {
    overflow: visible;
}

.search-input {
    flex-flow: row;
    margin-bottom: 20px;
    button {
        margin-left: 10px;
    }
}



