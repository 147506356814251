/* remove rounds from all elements */
div,
input,
select,
textarea,
img,
table,
td,
th,
p,
a,
button,
ul,
code,
pre,
li {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

/***
Buttons & Dropdown Buttons
***/
.btn {
    border-width: 0;
    padding: 7px 14px;
    font-size: 14px;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-text-shadow: none;
    -moz-text-shadow: none;
    text-shadow: none;
}

/* fix jumping group buttons */
.btn-group.btn-group-solid .btn + .btn,
.btn-group.btn-group-solid .btn + .btn-group.btn-group-solid,
.btn-group.btn-group-solid .btn-group.btn-group-solid + .btn,
.btn-group.btn-group-solid .btn-group.btn-group-solid + .btn-group.btn-group-solid {
    margin-left: 0px;
}

.btn-group-vertical.btn-group-solid > .btn + .btn,
.btn-group-vertical.btn-group-solid > .btn + .btn-group,
.btn-group-vertical.btn-group-solid > .btn-group + .btn,
.btn-group-vertical.btn-group-solid > .btn-group + .btn-group {
    margin-top: 0px;
    margin-left: 0;
}

.btn-default {
    border-width: 1px;
    padding: 6px 13px;
}

.btn.red-stripe {
    border-left: 3px solid #d84a38;
}

.btn.blue-stripe {
    border-left: 3px solid #4d90fe;
}

.btn.purple-stripe {
    border-left: 3px solid #852b99;
}

.btn.green-stripe {
    border-left: 3px solid #35aa47;
}

.btn.yellow-stripe {
    border-left: 3px solid #aaaaaa;
}

.btn.white-stripe {
    border-left: 3px solid #555555;
}

.btn.dark-stripe {
    border-left: 3px solid #555555;
}

.btn.default {
    color: #333333;
    text-shadow: none;
    background-color: #e5e5e5;
}

.btn.default:hover,
.btn.default:focus,
.btn.default:active,
.btn.default.active,
.btn.default[disabled],
.btn.default.disabled {
    color: #333333;
    background-color: #d8d8d8 !important;
    outline: none !important;
}

/*	Red */
.portlet .btn.red {
    background-color: #E02222;
}

.btn.red {
    color: white;
    text-shadow: none;
    background-color: #d84a38;
}

.btn.red:hover,
.btn.red:focus,
.btn.red:active,
.btn.red.active,
.btn.red[disabled],
.btn.red.disabled {
    background-color: #bb2413 !important;
    color: #fff !important;
    outline: none !important;
}

/*	Blue */
.btn.blue {
    color: white;
    text-shadow: none;
    background-color: #4d90fe;
}

.btn.blue:hover,
.btn.blue:focus,
.btn.blue:active,
.btn.blue.active,
.btn.blue[disabled],
.btn.blue.disabled {
    background-color: #0362fd !important;
    color: #fff !important;
    outline: none !important;
}

.btn-group .btn.blue.dropdown-toggle {
    background-color: #4d90fe !important;
}

.btn-group .btn.blue:hover,
.btn-group .btn.blue:focus,
.btn-group .btn.blue:active,
.btn-group .btn.blue.active,
.btn-group .btn.blue.disabled,
.btn-group .btn.blue[disabled] {
    background-color: #0362fd !important;
    color: #fff !important;
    outline: none !important;
}

.btn.blue input {
    background: none;
    border: none;
    color: #FFFFFF;
}

/*	Green */
.btn.green {
    color: white;
    text-shadow: none;
    background-color: #35aa47;
}

.btn.green:hover,
.btn.green:focus,
.btn.green:active,
.btn.green.active,
.btn.green.disabled,
.btn.green[disabled] {
    background-color: #1d943b !important;
    color: #fff !important;
    outline: none !important;
}

.btn.green input {
    background: none;
    border: none;
    color: #FFFFFF;
}

/* white */
.btn.white {
    color: #000;
    text-shadow: none;
    background-color: #fdfbfb;
}

.btn.white:hover,
.btn.white:focus,
.btn.white:active,
.btn.white.active,
.btn.white.disabled,
.btn.white[disabled] {
    background-color: #e6e6e6 !important;
    color: #000 !important;
    outline: none !important;
}

.btn.white input {
    background: none;
    border: none;
    color: #FFFFFF;
}

/*	Light-grey */
.btn.light-grey {
    color: white;
    text-shadow: none;
    background-color: #aaaaaa;
}

.btn.light-grey:hover,
.btn.light-grey:focus,
.btn.light-grey:active,
.btn.light-grey.active,
.btn.light-grey.disabled,
.btn.light-grey[disabled] {
    background-color: #888888 !important;
    color: #fff !important;
    outline: none !important;
}

.btn.light-grey input {
    background: none;
    border: none;
    color: #FFFFFF;
}

/*	Grey */
.btn.grey {
    color: white;
    text-shadow: none;
    background-color: #555555;
}

.btn.grey:hover,
.btn.grey:focus,
.btn.grey:active,
.btn.grey.active,
.btn.grey.disabled,
.btn.grey[disabled] {
    background-color: #5e5e5e !important;
    color: #fff !important;
    outline: none !important;
}

.btn.grey input {
    background: none;
    border: none;
    color: #FFFFFF;
}

/*	Purple */
.btn.purple {
    color: white;
    text-shadow: none;
    background-color: #852b99;
}

.btn.purple:hover,
.btn.purple:focus,
.btn.purple:active,
.btn.purple.active,
.btn.purple.disabled,
.btn.purple[disabled] {
    background-color: #6d1b81 !important;
    color: #fff !important;
    outline: none !important;
}

.btn-group .btn.purple.dropdown-toggle {
    background-color: #852b99 !important;
}

.btn-group .btn.purple:hover,
.btn-group .btn.purple:focus,
.btn-group .btn.purple:active,
.btn-group .btn.purple.active,
.btn-group .btn.purple.disabled,
.btn-group .btn.purple[disabled] {
    background-color: #6d1b81 !important;
    color: #fff !important;
    outline: none !important;
}

/*	Yellow */
.btn.yellow {
    color: white;
    text-shadow: none;
    background-color: #ffb848;
}

.btn.yellow:hover,
.btn.yellow:focus,
.btn.yellow:active,
.btn.yellow.active,
.btn.yellow.disabled,
.btn.yellow[disabled] {
    background-color: #eca22e !important;
    color: #fff !important;
    outline: none !important;
}

.btn-group .btn.yellow.dropdown-toggle {
    background-color: #ffb848 !important;
}

.btn-group .btn.yellow:hover,
.btn-group .btn.yellow:focus,
.btn-group .btn.yellow:active,
.btn-group .btn.yellow.active,
.btn-group .btn.yellow.disabled,
.btn-group .btn.yellow[disabled] {
    background-color: #eca22e !important;
    color: #fff !important;
    outline: none !important;
}

/*	Black */
.btn.dark {
    color: white;
    text-shadow: none;
    background-color: #555555;
}

.btn.dark:hover,
.btn.dark:focus,
.btn.dark:active,
.btn.dark.active,
.btn.dark.disabled,
.btn.dark[disabled] {
    background-color: #222222 !important;
    color: #fff !important;
    outline: none !important;
}

.btn.dark input {
    background: none;
    border: none;
    color: #FFFFFF;
}

.btn-group .btn.dark.dropdown-toggle {
    background-color: #555555 !important;
}

.btn-group .btn.dark:hover,
.btn-group .btn.dark:focus,
.btn-group .btn.dark:active,
.btn-group .btn.dark.active,
.btn-group .btn.dark.disabled,
.btn-group .btn.dark[disabled] {
    background-color: #222222 !important;
    color: #fff !important;
    outline: none !important;
}

.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    vertical-align: middle;
}

.btn-lg > i {
    font-size: 18px;
}

.btn > i {
    font-size: 14px;
}

.btn-sm,
.btn-xs {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-sm > i,
.btn-xs > i {
    font-size: 13px;
}

.btn-xs {
    padding: 1px 5px;
}

.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

/***
Metro icons
***/
[class^="m-icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-top: 3px;
    line-height: 14px;
    vertical-align: top;
    background-image: url(../../images/syncfusion-icons.png);
    background-position: 0 0;
    background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 6px;
    vertical-align: middle;
    background-image: url(../../images/syncfusion-icons.png);
    background-position: 0 0px;
    background-repeat: no-repeat;
}

/* large icons */
.btn.m-icon-big {
    padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
    padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
    margin: 0 0 0 10px;
}

.btn.m-icon-ony > i {
    margin-left: 0px;
}

/* default icons */
.btn.m-icon {
    padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
    margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
    padding: 7px 10px 7px 6px;
}

/* white icon */
.m-icon-white {
    background-image: url(../../images/syncfusion-icons-white.png);
}

/*	Misc */
.m-icon-swapright {
    background-position: -27px -10px;
}

.m-icon-swapdown {
    background-position: -68px -10px;
}

.m-icon-swapleft {
    background-position: -8px -10px;
}

.m-icon-swapup {
    background-position: -46px -10px;
}

.m-icon-big-swapright {
    background-position: -42px -28px;
}

.m-icon-big-swapdown {
    background-position: -115px -28px;
}

.m-icon-big-swapleft {
    background-position: -6px -28px;
}

.m-icon-big-swapup {
    background-position: -78px -28px;
}

/***
Popover
 ***/
.popover {
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    padding: 0 !important;
}

.popover .popover-title {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
}

.info .popover .popover-title,
.popover.info .popover-title,
.info .popover .popover-content,
.popover.info .popover-content {
    color: #27a9e3;
}

.success .popover .popover-title,
.popover.success .popover-title,
.success .popover .popover-content,
.popover.success .popover-content {
    color: #468847;
}

.error .popover .popover-title,
.popover.error .popover-title,
.error .popover .popover-content,
.popover.error .popover-content {
    color: #B94A48;
}

.warning .popover .popover-title,
.popover.warning .popover-title,
.warning .popover .popover-content,
.popover.warning .popover-content {
    color: #C09853;
}

/***
Dropdown
***/
/*Fixing dropdown issue on mobile devices in Bootstrap 3.2.2*/
.dropdown-backdrop {
    position: static;
}

.dropdown:hover .caret,
.open.dropdown .caret {
    opacity: 1;
    filter: alpha(opacity=100);
}

.dropdown.open .dropdown-toggle {
    color: #08c;
    background: #ccc;
    background: rgba(0, 0, 0, 0.3);
}

/***
Dropdown Menu
***/
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 0px;
    margin: 0px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: "Segoe UI", Helvetica, Arial, sans-serif;
    border: 1px solid #ddd;
}

.dropdown.inline .dropdown-menu {
    display: inline-block;
    position: relative;
}

.dropdown-menu.bottom-up {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

.dropdown-menu li > a {
    padding: 6px 13px 6px 13px;
    color: #333;
    text-decoration: none;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    white-space: nowrap;
}

.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
    text-decoration: none;
    background-image: none;
    background-color: #eee;
    color: #333;
    filter: none;
}

/* dropdown sub menu support for Bootsrap 3 */
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
}

.dropdown-submenu > a:after {
    position: absolute;
    display: inline-block;
    font-size: 14px;
    right: 7px;
    top: 9px;
    font-family: FontAwesome;
    height: auto;
    content: "\f105";
    font-weight: 300;
}

/* hack for chrome and safari */
@media all and (-webkit-min-device-pixel-ratio: 0) {
    .dropdown-submenu > a:after {
        top: 7px;
    }

}

.ie1 .dropdown-submenu > a:after {
    margin-top: 2px;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.nav.pull-right > li > .dropdown-menu:before,
.nav > li > .dropdown-menu.pull-right:before {
    right: 12px;
    left: auto;
}

.nav.pull-right > li > .dropdown-menu:after,
.nav > li > .dropdown-menu.pull-right:after {
    right: 13px;
    left: auto;
}

.nav.pull-right > li > .dropdown-menu .dropdown-menu,
.nav > li > .dropdown-menu.pull-right .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: -1px;
    margin-left: 0;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        float: left;
        width: auto;
        margin-top: 0;
        background-color: #ffffff;
        border: 1px solid #ddd;
        -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }

    .navbar-nav .open .dropdown-menu > li > a {
        padding: 6px 0 6px 13px;
        color: #333 !important;
    }

    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
        background-color: #eee !important;
    }

}

/* bagin: sidebar menu badges */
/***
Dropdown Checkboxes (in v1.3)
***/
.dropdown-checkboxes {
    padding: 5px;
}

.dropdown-checkboxes label {
    display: block;
    font-weight: 300;
    color: #333;
    margin-bottom: 4px;
    margin-top: 4px;
}

/***
Dropdown Menu Badges
***/
.dropdown-menu > li > a > .badge {
    position: absolute;
    margin-top: 1px;
    right: 3px;
    display: inline;
    font-size: 11px;
    font-weight: 300;
    text-shadow: none;
    height: 18px;
    padding: 3px 6px 3px 6px;
    text-align: center;
    vertical-align: middle;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
}

.dropdown-menu > li > a > .badge.badge-roundless {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

/* end: sidebar menu badges */
/***
Forms
***/
code {
    border: 1px solid #e1e1e1;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

label {
    font-weight: 400;
    font-size: 14px;
}

.form-control:-moz-placeholder {
    color: #999999;
}

.form-control::-moz-placeholder {
    color: #999999;
}

.form-control:-ms-input-placeholder {
    color: #999999;
}

.form-control::-webkit-input-placeholder {
    color: #999999;
}

.form-control {
    font-size: 14px;
    font-weight: normal;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: #999999;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #eeeeee;
}

.uneditable-input {
    padding: 6px 12px;
    min-width: 206px;
    font-size: 14px;
    font-weight: normal;
    height: 34px;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

label.form-control {
    display: block;
    margin-bottom: 5px;
}

input[disabled],
select[disabled],
textarea[disabled] {
    cursor: not-allowed;
    background-color: #F4F4F4 !important;
}

input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;
    background-color: #F9F9F9 !important;
}

/* input	groups */
.input-group.input-group-fixed {
    width: auto !important;
}

.input-group-addon {
    border-color: #e5e5e5;
    background: #e5e5e5;
}

.input-group-addon > i {
    color: #999;
}

/* form control sizing */
.form-control-inline {
    display: inline-block !important;
}

.input-xsmall {
    width: 60px !important;
}

.input-small {
    width: 120px !important;
}

.input-medium {
    width: 240px !important;
}

.input-large {
    width: 320px !important;
}

.input-xlarge {
    width: 480px !important;
}

/***
Input spinner(in v1.4)
***/
input[type="text"].spinner,
input[type="password"].spinner,
input[type="datetime"].spinner,
input[type="datetime-local"].spinner,
input[type="date"].spinner,
input[type="month"].spinner,
input[type="time"].spinner,
input[type="week"].spinner,
input[type="number"].spinner,
input[type="email"].spinner,
input[type="url"].spinner,
input[type="search"].spinner,
input[type="tel"].spinner,
input[type="color"].spinner {
    background-image: url("../../images/input-spinner.gif") !important;
    background-repeat: no-repeat;
    background-position: right 8px;
}

@media (max-width: 768px) {
    .input-large {
        width: 250px !important;
    }

    .input-xlarge {
        width: 300px !important;
    }

}

/***
Error States
***/
.has-warning .help-inline,
.has-warning .help-block,
.has-warning .control-label {
    color: #c09853;
}

.has-warning .form-control {
    border-color: #c09853;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:focus {
    border-color: #a47e3c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .input-group-addon {
    color: #c09853;
    background-color: #fcf8e3;
    border-color: #c09853;
}

.has-error .help-inline,
.has-error .help-block,
.has-error .control-label {
    color: #b94a48;
}

.has-error .form-control {
    border-color: #b94a48;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:focus {
    border-color: #953b39;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .input-group-addon {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #b94a48;
}

.has-success .help-inline,
.has-success .help-block,
.has-success .control-label {
    color: #468847;
}

.has-success .form-control {
    border-color: #468847;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:focus {
    border-color: #356635;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .input-group-addon {
    color: #468847;
    background-color: #dff0d8;
    border-color: #468847;
}

/***
Custom label and badges
***/
.label,
.badge {
    font-weight: 300;
    text-shadow: none !important;
}

.label {
    font-size: 12px;
    padding: 3px 6px 3px 6px;
}

.label.label-sm {
    font-size: 12px;
    padding: 1px 4px 1px 4px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label,
h7 .label {
    font-size: 75%;
}

.badge {
    font-size: 11px !important;
    font-weight: 300;
    background-color: #e02222;
    height: 18px;
    padding: 3px 6px 3px 6px;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    text-shadow: none !important;
    text-align: center;
    vertical-align: middle;
}

.badge.badge-roundless {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.badge-default,
.label-default {
    background-color: #999 !important;
}

.badge-primary,
.label-primary {
    background-color: #428bca !important;
}

.label-success,
.badge-success {
    background-color: #3cc051;
    background-image: none !important;
}

.label-warning,
.badge-warning {
    background-color: #fcb322;
    background-image: none !important;
}

.label-danger,
.badge-danger {
    background-color: #ed4e2a;
    background-image: none !important;
}

.label-info,
.badge-info {
    background-color: #57b5e3;
    background-image: none !important;
}

/* fix badge position for navs */
.nav.nav-pills > li > a > .badge {
    margin-top: -2px;
}

.nav.nav-stacked > li > a > .badge {
    margin-top: 1px;
    margin-bottom: 0px;
}

/***
Iconic Labels
***/
.label.label-icon {
    padding: 4px 1px 4px 5px;
    margin-right: 2px;
    text-align: center !important;
}

.ie9 .label.label-icon,
.ie10 .label.label-icon {
    padding: 3px 0px 3px 3px;
}

.label.label-icon > i {
    font-size: 12px;
    text-align: center !important;
}

/***
Progress Bars
***/
.progress {
    border: 0;
    background-image: none !important;
    filter: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.progress > .progress-bar-success {
    background-color: #3cc051;
}

.progress > .progress-bar-danger {
    background-color: #ed4e2a;
}

.progress > .progress-bar-info {
    background-color: #57b5e3;
}

.progress > .progress-bar-warning {
    background-color: #fcb322;
}

/***
Pagination
***/
.pagination {
    margin: 10px 0;
}

.pagination .active > a,
.pagination .active > a:hover {
    background: #eee;
    border-color: #dddddd;
    color: #333;
}

/***
wells
***/
.well {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/* Bootstrap Tabs */
.dropup.open > .dropdown-toggle,
.dropdown.open > .dropdown-toggle {
    border-color: #ddd !important;
}

.nav-tabs > li > .dropdown-menu:after,
.nav-pills > li > .dropdown-menu:after,
.navbar-nav > li > .dropdown-menu:after,
.nav-tabs > li > .dropdown-menu:before,
.nav-pills > li > .dropdown-menu:before,
.navbar-nav > li > .dropdown-menu:before {
    display: none !important;
}

.nav-tabs > .dropdown.open > .dropdown-toggle,
.nav-pills > .dropdown.open > .dropdown-toggle {
    background: #eee !important;
    color: #0d638f !important;
}

.nav-tabs,
.nav-pills {
    margin-bottom: 10px;
}

.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: #ffffff;
}

.tabs-below > .nav-tabs,
.tabs-below > .nav-pills {
    border-bottom: 0;
    margin-bottom: 0px;
    margin-top: 10px;
}

.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
    margin-bottom: 0;
    margin-top: 10px;
}

.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
    border-top-color: #ddd;
    border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
    border-color: transparent #ddd #ddd #ddd;
}

/***
Bootstrap modal 
***/
.modal {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    left: 50%;
    margin-left: -280px;
    outline: medium none;
    position: fixed;
    top: 10%;
    width: 560px;
    z-index: 10050 !important;
    outline: none !important;
}

.modal-header {
    border-bottom: 1px solid #EFEFEF;
}

.modal-header h3 {
    font-weight: 300;
}

.modal-wide {
    width: 60% !important;
}

.modal-full {
    width: 100% !important;
}

.modal.fade {
    top: 10%;
    transition: opacity 0.3s linear 0s, top 0.3s ease-out 0s;
}

/***
Modal header close button fix
***/
.modal-header .close {
    margin-top: 0px !important;
}

.modal > .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
}

.modal-backdrop {
    border: 0 !important;
    outline: none !important;
    z-index: 10049 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    background-color: #333 !important;
}

.modal-open .header .container-fluid {
    padding-right: 37px;
}

/***
Image Carousel
***/
.carousel.image-carousel .carousel-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.carousel.image-carousel .carousel-control i {
    position: absolute;
    top: 40%;
}

.carousel.image-carousel.image-carousel-hoverable .carousel-control i {
    display: none;
}

.carousel.image-carousel.image-carousel-hoverable:hover .carousel-control i {
    display: inline-block;
}

.carousel.image-carousel .carousel-control.left i {
    left: 10px;
}

.carousel.image-carousel .carousel-control.right i {
    right: 10px;
}

.carousel.image-carousel .carousel-indicators {
    margin-top: 10px;
    bottom: -7px;
}

.carousel.image-carousel .carousel-indicators li {
    background-color: #666;
}

.carousel.image-carousel .carousel-indicators li.active {
    background-color: #333;
}

.carousel.image-carousel .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px 15px 25px 15px;
    background: #333333;
    background: rgba(0, 0, 0, 0.75);
}

.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption p {
    text-align: left;
    line-height: 20px;
    color: #ffffff;
}

.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h4 {
    margin: 0 0 5px;
}

.carousel.image-carousel .carousel-caption h2 a,
.carousel.image-carousel .carousel-caption h3 a,
.carousel.image-carousel .carousel-caption h4 a {
    color: #aaa;
}

.carousel.image-carousel .carousel-caption p {
    margin-bottom: 0;
}

.carousel.image-carousel .item {
    margin: 0;
}

/***
Bootstrap Tables
***/
.table thead > tr > th {
    border-bottom: 0;
}

.table tbody tr.active td,
.table tbody tr.active th {
    background-color: #e9e9e9 !important;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
    background-color: #e1e1e1 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

/***
Bootstrap Panel
***/
.panel {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.pull-right .icon-action-perso {
    color: #333333;
    font-size: 20px;
    padding: 5px 7px;
    vertical-align: middle;
}

/* form message Bastien */
.right {
    float: right;
}

.left {
    float: left;
}

.error_list {
    color: #FFF;
    background-color: #ff6363;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 5px;
}

.input-inline .error_list {
    position: absolute;
    padding: 8px 5px;
}

.error_list li {
    list-style: none;
}

.filters form {
    padding-bottom: 15px;
}

.job {
    margin-bottom: 10px;
    width: 75%;
}

.odd > th,
.odd > td {
    background: #fff !important;
}

.even > th,
.even > td {
    background: #f4f4f4 !important;
}

.clear {
    clear: both;
}

.lightbox {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: url(../../images/bg-shadow-box.png);
}

.lightbox .content-lightbox {
    width: 300px;
    margin: 200px auto 0;
    padding: 20px;
    text-align: center;
    background-color: #FFF;
    border: 1px solid #AAA;
}

.lightbox .content-lightbox-iframe {
    /* width: 1050px; */

    /* margin: 200px auto 0; */

    width: 90%;
    height: 90%;
    margin: 15px auto 0;
    padding: 20px;
    text-align: center;
    background-color: #FFF;
    border: 1px solid #AAA;
}

.lightbox .content-lightbox .left-button-lightbox {
    float: left;
    margin: 20px 0 0 25%;
    color: green;
    cursor: pointer;
}

.lightbox .content-lightbox .right-button-lightbox {
    float: right;
    margin: 20px 25% 0 0;
    color: red;
    cursor: pointer;
}

.lightbox .content-lightbox .left-button-lightbox [class^="icon-"],
[class*=" icon-"],
.lightbox .content-lightbox .right-button-lightbox [class^="icon-"],
[class*=" icon-"] {
    font-size: 25px;
}

.table.valign-top thead > tr > th,
.table.valign-top thead > tr:first-child > th {
    vertical-align: middle;
    border: 1px solid #BBBBBB;
    text-align: center;
}

.table.valign-top thead > tr > th.tablesorter-header .sprite-arrow {
    background: url(../../images/sprite-arrow-table.png) 0 0 no-repeat;
}

.table.valign-top thead > tr > th.tablesorter-header.tablesorter-headerAsc .sprite-arrow {
    background: url(../../images/sprite-arrow-table.png) 0 -40px no-repeat;
}

.table.valign-top thead > tr > th.tablesorter-header.tablesorter-headerDesc .sprite-arrow {
    background: url(../../images/sprite-arrow-table.png) 0 -20px no-repeat;
}

.table.valign-top thead > tr > th.tablesorter-header.sorter-false .sprite-arrow {
    background: none;
    display: none;
}

td.red {
    background: #FF9EA0 !important;
}

td.yellow {
    background: #FFD078 !important;
}

td.green {
    background: #AFFFC3 !important;
}

td.grey {
    background: #555555 !important;
    color: #fff;
}

td.light-grey {
    background: #aaa !important;
    color: #fff;
}

td.white {
    background: #fefefe !important;
    color: #000;
}

.overlay {
    position: absolute;
    background: #fff;
    z-index: 10;
}

.add-risk-button {
    margin: auto;
    padding: 10px;
    z-index: 11;
    width: 120px;
    position: relative;
}

.add-risk-button .btn {
    z-index: 11;
    position: absolute;
}

#breadcrumb-container {
    height: 61px;
    position: relative;
    z-index: 10000;
}

.list-risk .list-group-item .row {
    min-height: 47px;
}

.prevention-hr {
    margin: 2px 0;
}

.hide-frequency,
.hide-riskcharacter,
.hide-seriousness,
.hide-mastery,
.hide-quotation,
.hide-affectations,
.hide-hardness,
.hide-jobs,
.hide-cmr,
.hide-sensibility,
.hide-firms,
.hide-priority,
.hide-units {
    display: none;
}

.portlet.help .portlet-body {
    border-top: 1px solid #9d9c9c;
}

.portlet.help > div > img {
    float: right;
    margin-top: -27px;
    margin-right: 100px;
}

.mini-input {
    width: 50px;
}

.popover-error {
    background-color: #D84A38;
    color: #FFF;
    z-index: 1;
}

.popover.popover-error.top .arrow:after {
    border-top-color: #D84A38;
}

.popover.popover-error.left .arrow:after {
    border-left-color: #D84A38;
}

.popover.popover-error.right .arrow:after {
    border-right-color: #D84A38;
}

.popover.popover-error.bottom .arrow:after {
    border-bottom-color: #D84A38;
}

.popover-error li {
    list-style: none;
}

/* modal inactivity-modal */
#inactivity-modal h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

#inactivity-modal .modal-header {
    background-color: #acacac;
    color: #FFF;
}

#inactivity-modal h4 {
    color: #929292;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
}

#inactivity-modal #modal-body-left {
    width: 140px;
    text-align: center;
    float: left;
}

#inactivity-modal #modal-body-right {
    text-align: left;
    float: left;
    width: 360px;
    color: #929292;
    font-size: 14px;
}

/**/
#help-content {
    display: none;
    z-index: 1000;
    right: 5px;
    position: absolute;
    overflow: auto;
    margin-top: 40px;
    border: 1px solid #9d9c9c;
    padding: 0;
}

#help-content * {
    border: 0 !important;
}

#help-content .portlet {
    margin-bottom: 0;
}

.edit-action {
    padding-left: 17px;
}

.edit-action > .action {
    cursor: pointer;
}

.edit-action .action-hide {
    display: block;
}

.edit-action.open .action-hide {
    display: none;
}

.edit-action .action-show {
    display: none;
}

.edit-action.open .action-show {
    display: block;
}

.affix#main-menu {
    position: fixed;
    padding-top: 38px;
    z-index: 800;
}

.product-out td {
    background: #eee !important;
}

.pair, .pair:hover, .pair td, .pair:hover td, .pair td:hover {
    background: #f0f0f0 !important;
}

.unpair, .unpair:hover, .unpair td, .unpair:hover td, .unpair td:hover {
    background: #fff !important;
}

.not-accessible {
    cursor: not-allowed;
}
