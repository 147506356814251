@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/open_sans-webfont.woff2') format('woff2'),
    url('../../fonts/open_sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/open_sans_light-webfont.woff2') format('woff2'),
    url('../../fonts/open_sans_light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/open_sans_semibold-webfont.woff2') format('woff2'),
    url('../../fonts/open_sans_semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/open_sans_bold-webfont.woff2') format('woff2'),
    url('../../fonts/open_sans_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}