













































































.popover-content {
  max-height: 85vh;
  overflow: auto;
}
  .help-popover{
    width: 50vw;
    max-width: 1100px;
    max-height: 75vh;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 12px;
    line-height: 1.2;
  }
