.tree .tree-item:hover {
    background: none !important;
}

.saveAndClose {
    margin-right: 20px;
}

.folder-info li {
    font-size: 14px;
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.folder-info ul li:last-child {
    border: 0;
}

.folder-info li .pull-right {
    margin-top: 6px;
    font-size: 14px;
}

.fixed-button {
    position: fixed;
    bottom: 0;
    right: 15px;
}

.long-title th {
    font-size: 12px !important;
    width: 9%;
}

.page-content .page-breadcrumb.breadcrumb.affix > .btn.help-button {
    right: 99px;
}

.help-button {
    margin-top: -8px;
    padding: 8px 14px;
    position: absolute;
    right: 114px;
    color: #fff;
    opacity: 0.7;
}

.page-content .page-breadcrumb.breadcrumb > li > i.icon-question {
    color: #fff;
}

#black-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.75;
    z-index: 5000;
    top: 0px;
}

.area_hover {
    height: 215px !important;
    left: 25% !important;
    position: fixed !important;
    top: 20% !important;
    width: 50% !important;
    z-index: 10000 !important;
}

select.area_hover {
    height: 30px !important;
    margin-top: -30px !important;
    text-align: left;
}

.area_hover_2 {
    left: 25% !important;
    position: fixed !important;
    top: 20% !important;
    width: 50% !important;
    z-index: 10000 !important;
}

#plan_global tbody tr td {
    vertical-align: middle;
}

#plan_global tbody tr td input[type=text] {
    width: inherit !important;
}

#plan_global tbody tr td input[type=textarea] {
    max-width: 100px !important;
}

#arrow {
    position: absolute;
}

.note-info {
    background-color: white;
    border-width: 2px;
    border-style: solid;
    border-color: black;
}

.accordion {
    cursor: pointer;
    /*background: url("../images/accordion-close.png") no-repeat 0 3px;*/
    padding-left: 16px;
}

.accordion.open {
    /*background: url("../images/accordion-open.png") no-repeat -1px 3px;*/
}

.accordion-target {
    margin: 3px 0 15px;
    padding-left: 16px;
    color: #333;
}

.accordion-hidden {
    display: none;
}

.information-quotation-col {
    margin-top: 10px;
}

.risque-quotation-desc{
    margin-bottom : 20px;
}
.risque-quotation-desc p {
    margin-bottom : 0;
}

.information-quotation {
    display: none;
    color: #FFF;
    background-color: #6ea2f6;
    padding: 5px 0 5px 15px;
}

.information-quotation svg {
    color: #FFF;
    margin-right: 10px;
}

.preview-criticity-col {
    display: none;
}

.btn-grp-fix {
    min-width: 174px !important;
}

.select2-modif {
    width: auto !important;
}

.select2-search {
    padding-top: 5px;
    border-top: 1px solid #999999;
}

.fix-row-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.no-pad-lft {
    padding-left: 0 !important;
}

.pie-chart > div{
    margin: auto;
    margin-bottom : 10px;
    margin-top : 10px;
}

/*#pie-cmr > canvas {*/
/*background-image: url("../");*/
/*}*/

/*#pie-sensibility > canvas {*/
/*background-image: url("../");*/
/*}*/

#pie-risks-quotations {
    background-image: url("../../images/icon-search.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
}



li.no-border {
    border-bottom: none;
}

div.divider {
    display: block;
    width: 75%;
    border-bottom: 1px solid #ccc;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.firm-group-li {
    color: red !important;
}

.test {
    background-color: white !important;
    color: #aaa !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 5px !important;
    border-left: 2px solid #aaa !important;
}

@media (min-width: 768px) {
    .parent-vcenter {
        position: relative;
    }

    .vcenter {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .pie-vcenter {
        float: right;
    }
}

.pie-vcenter {
    position: initial;
}

.pie-chart > div.tooltip {
    background: rgba(0, 0, 0, .7);
    border: 1px;
    border-radius: 8px !important;
    opacity: 1;
    /*opacity: 0;*/
    /*background-color: #4b8df8;*/
    color: white;
    min-width: 100px;
    max-width: 150px;
    text-align: center;
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, 0);
}

.pie-parent {
    position: relative;
}

.table-rotate-315 {
    padding-right: 200px;
}

#matrix-employee {
    width: 100%;
}

#matrix-employee thead tr {
    height: 150px;
}

#matrix-employee th:nth-child(1) {
    width: 50%;
}

#matrix-employee th:nth-child(2) {
    width: 10%;
}

#matrix-employee th:nth-child(3) {
    width: 40%;
}

#matrix-employee tbody td {
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 10px;
}

#matrix-employee .hardness, #matrix-legend .hardness {
    background-color: #555555 !important;
    color: white !important;
    font-weight: bold;
    text-align: center;
}

/*#matrix-employee .hardness-ignore, #matrix-legend .hardness-ignore {*/
/*background-color: inherit !important*/
/*}*/
#matrix-legend tr > td:nth-child(2) {
    width: 50px;
}

th.rotate-315 > div {
    transform: translate(56px, 28px) rotate(315deg);
    width: 85px;
}

th.rotate-315 > div > span {
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    width: 200px;
    height: 50px;
    flex: 1;
    align-items: flex-end;
}

/*th.rotate-315 > div > span.hardness-risk{*/
/*padding: 5px 5px 5px 25px;*/
/*background-image: url("../images/icon-hardness-warning.png");*/
/*background-position: 0px 25px;*/
/*background-size: 20px;*/
/*background-repeat: no-repeat;*/
/*}*/

.bilan-risk-employee {
    margin-top: 30px;
    font-size: 10px;
}

.bilan-risk-employee table {
    width: 500px;
    display: inline-block;
}

.bilan-risk-employee th {
    font-size: 13px;
}

.bilan-risk-employee th, .bilan-risk-employee td {
    border: 1px solid #ccc;
    padding: 5px 10px;
}

.table-risk-question {
    width: 100%;
}

.table-risk-question td {
    padding: 5px 0;
}

.table-risk-question td:nth-child(1) {
    width: calc(100% - 150px);
}

.table-risk-question td:nth-child(2) {
    width: 150px;
}

select[disabled]::-ms-expand {
    display: none;
}

select[disabled] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.uncertainDiagSpan {
    display: block;
    text-align: center;

}

/*Tableau de synthèse risque pro penibilité*/
#proHardnessReport {
    background-color: red;
}

#proHardnessReport thead .pre-head th {
    vertical-align: bottom;
    font-size: 14px;
}

#proHardnessReport thead th {
    font-size: 12px;
}

#proHardnessReport tbody {
    font-size: 10px;
}

#proHardnessReport tbody td {
    vertical-align: middle;
}

/*Tableau de synthèse risque pro penibilité*/
#proHardnessReport tr.facteur > td:first-child {
    background: #f0f0f0 !important;
}

#proHardnessReport tr.job-divider {
    border-bottom: 4px solid #bbb;
}

#proHardnessReport tr.employee-divider {
    height: 2px;
    border-bottom: 4px solid grey;
}

#proHardnessReport tr.employee-divider > td {
    padding: 0;
}

#proHardnessReport td.phrase-divider {
    height: 2px;
    border-bottom: 1px solid black;
}

#proHardnessReport .head td {
    background: #ccc;
}

#proHardnessReport .pre-head {
    height: 75px;
    line-height: 75px;
    vertical-align: middle;
}

#proHardnessReport .pre-head th {
    background: #bbb;
    vertical-align: middle;
}

td.hardness {
    background: #555555 !important;
    color: white;
    text-align: center;
    font-weight: bold;
}

td.hardness-in-progress {
    background: #929292 !important;
    color: white;
    text-align: center;
    font-weight: bold;
}

td.not-hardness {
    text-align: center;
}

.align-center {
    text-align: center;
    vertical-align: middle;
}

.to-define {
    background-color: #aaa !important;
}

#oldproduct {
    margin-top: 3px;
    background-color: #999;
    vertical-align: baseline;
}

#oldproduct ~ label {
    color: #999;
    line-height: 34px;
    margin-left: 10px;
}

#autocomplete {
    font-size: 14px;
    font-weight: normal;
    color: #000;
}

.action-round {
    display: inline-block;
    margin: 0 10px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
}

.action-exceeded {
    color: red;
}

.action-abandon {
    opacity: 0.5;
}