.el-form-item {
  &__label {
    line-height: inherit;
    word-break: break-word;
  }

  &.align-right {
    float: right;

    &.last {
      margin-right: 15px;
    }
  }

}

.el-radio-group-vertical {
  .el-radio {
    width: 100%;
  }
}

input[type=file] {
  display: none;
}

.productSheet-select {
  .el-select-dropdown__item.z-0 {
    height: 80px;
  }
}

.productSheet-options {
  display: flex;
  flex-flow: column;
}

.portlet-body.form-col2 {
  .el-form-item {
    display: flex;
    align-items: center;

    &__content {
      width: 100%;
    }

    label {
      width: 25%;
      flex-shrink: 0;
    }
  }
}

.pro-action-plan-filter {
  flex-wrap: nowrap;

  & > div {
    margin-right: 10px;
    flex-shrink: 1;

    &:last-child {
      flex-shrink: 0;
      margin-right: 0;
    }
  }
}

.checkbox-picto {
  display: inline-flex;
  align-items: flex-end;

  .el-checkbox__label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 40px;
    margin-bottom: 3px;
  }
}

.el-tree {
  background: transparent;
}

.el-tree .is-checked > .el-tree-node__children .el-checkbox__input {
  cursor: not-allowed;
  opacity: 0.5;
}

.el-select{
  input[readonly]{
    background: #fff !important;
  }
}
//.error-warning{
//  .el-form-item__error{
//    color: #C36B08;
//  }
//  .el-form-item.is-error .el-input__inner{
//    border-color: #C36B08;
//  }
//}